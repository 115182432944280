export abstract class ReactiveAbstract {
    watched: ReactiveAbstract;
    setHandlers = {};

    protected constructor() {
        this.watched = new Proxy(this, {
            get: function (target, prop, receiver) {
                return Reflect.get(target, prop, receiver);
            },
            set: (obj, prop, value) => {
                obj[prop] = value;
                Object.keys(this.setHandlers).forEach(key => {
                    (key === prop) && this.setHandlers[key](value);
                })
                return true;
            }
        })
    }
}
