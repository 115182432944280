import {CreateReconstruction} from "./type/createReconstruction";
import {btnProgressOff, btnProgressOn, getById, hide, show, showInvalidField, showMessage, toggle} from "./helpers";
import {createPreview, firebase, functions} from "./index";
import {FileUpload} from "./file-upload";
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {config} from "./config";
import {MapstarUI} from "./mapstar-ui";
import {v4 as uuidv4} from 'uuid';
import * as ProgressBar from 'progressbar.js'
import {CreateItem} from "./type/createItem";
import {itemCategories} from "./item-categories";
import FullMetadata = firebase.storage.FullMetadata;

export class MapstarUpload {
    mapstarUI: MapstarUI;
    map;

    constructor(mapstarUI) {
        this.mapstarUI = mapstarUI;
    }

    addUploadForm() {
        if (this.mapstarUI.isEditMode) {
            this.mapstarUI.triggerEditConfirm(() => { this.initForm() });
            return;
        }
        this.initForm();
    }

    private initForm() {
        this.mapstarUI.addUiTemplate('upload-model-tpl');
        this.createDraggableMap();

        this.bindGps();
        this.bindAgreement();
        this.bindDragnDrop();
        this.bindSwitcher();
        this.populateCategories();
        (document.querySelector('.upload-model .close-icon') as HTMLElement).onclick = () => {
            this.closeUploadModel()
        };

        this.addUploadModel();
    }

    private addUploadModel() {
        document.querySelector('.upload-model .title').innerHTML = 'Upload a model';
        getById('upload-model-save').onclick = async () => { await this.uploadModel() };
        document.querySelector('#glb-model-file .info span').innerHTML = String(this.mapstarUI.profile.modelSizeLimit / 1024 / 1024);
        show(getById('gps-row'));
        document.querySelector('#gps .turn-on.active') ? show(getById('map-block')) : hide(getById('map-block'));
    }

    private addUploadItem() {
        document.querySelector('.upload-model .title').innerHTML = 'Upload an item';
        getById('upload-model-save').onclick = async () => { await this.uploadItem() };
        document.querySelector('#glb-model-file .info span').innerHTML = String(this.mapstarUI.profile.itemSizeLimit / 1024 / 1024);
        hide(getById('gps-row'));
        hide(getById('map-block'));
    }

    private bindGps() {
        getById('gps').addEventListener('click', (e) => {
                const el: HTMLElement = e.currentTarget as HTMLElement;
                toggle(getById('map-block'));
                el.querySelectorAll('button').forEach(b => b.classList.toggle('active'));
            }
        )
    }

    private bindAgreement() {
        (document.querySelector('input[name=agreement]') as HTMLElement).onclick = (e) => {
            if ((e.currentTarget as HTMLInputElement).checked == true) {
                this.enableSaveBtn();
            } else {
                this.disableSaveBtn();
            }
        }
    }

    private bindSwitcher() {
        getById('upload-form-type').addEventListener('click', (e) => {
                const el: HTMLElement = e.currentTarget as HTMLElement;
                if (el.querySelector('.active').classList.contains('item')) {
                    // getById('upload-item').remove();
                    this.addUploadModel();
                } else {
                    // getById('upload-model').remove();
                    this.addUploadItem();
                }
                el.querySelectorAll('button').forEach(b => b.classList.toggle('active'));
            }
        )
    }

    private bindDragnDrop() {
        document.querySelectorAll('.upload-model .drop-area').forEach((dropArea: HTMLElement, i) => {
            const FU = new FileUpload(dropArea);
            if (i === 0) {
                FU.onFileSelected = (files: FileList) => {
                    createPreview(files[0], '.gallery img');
                    this.enableSaveBtn();
                }
            } else {
                FU.hasImagePreview = true;
            }
        })
    }

    private populateCategories() {
        const categoryDiv = getById('category');
        for (let key in itemCategories) {
            const option = document.createElement('input');
            option.type = 'radio';
            option.name = 'category';
            option.value = key;
            option.id = 'category-' + key;
            categoryDiv.appendChild(option);

            const label: HTMLLabelElement = document.createElement('label');
            label.innerText = itemCategories[key].title;
            label.htmlFor = 'category-' + key;
            categoryDiv.appendChild(label);
        }
    }

    openUploadModel() {
        this.addUploadForm()
        toggle(getById('user-menu'));
        this.mapstarUI.closeMyLayers();
    }
    closeUploadModel() {
        this.removeMap();
        this.mapstarUI.closeModals();
        getById('upload-model')?.remove();
        getById('upload-item')?.remove();
    }

    async uploadModel() {
        let formData: CreateReconstruction;
        const gpsActive = document.querySelector('#gps .active').classList.contains('turn-on');
        const title = (getById('reconstruction-title') as HTMLInputElement).value;
        const description = (getById('reconstruction-description') as HTMLTextAreaElement).value;
        const latitude = gpsActive ? parseFloat((getById('latitude') as HTMLInputElement).value) : 0;
        const longitude = gpsActive ? parseFloat((getById('longitude') as HTMLInputElement).value) : 0;

        let isOk = true;
        if (!title.length) {
            showInvalidField('#reconstruction-title');
            isOk = false;
        }
        if (isNaN(latitude)) {
            showInvalidField('#latitude');
            isOk = false;
        }
        if (isNaN(longitude)) {
            showInvalidField('#longitude');
            isOk = false;
        }
        if(!document.querySelector('input[name=agreement]:checked')) {
            showInvalidField('#agreement');
            isOk = false;
        }
        if (!isOk) {
            return
        }
        const category = (document.querySelector('input[name=category]:checked') as HTMLInputElement)?.value
        if(!category) {
            showMessage('#errors-output', 'Please select a category');
            return
        }

       if(! await this.validateModel()) {
           return;
       }

        const files = await this.uploadFiles(this.mapstarUI.profile.modelSizeLimit);
        if(!files) {
            return;
        }

        formData = {
            anchor: {
                coordinates: {
                    latitude,
                    longitude,
                    altitude: 0
                }
            },
            visibility: this.mapstarUI.profile.privacy.visibility,
            layer: {
                title,
                description,
                items: [],
                preview: files.preview,
            },
            file: files.file,
            category
        }
        const res = await functions.httpsCallable('CreateReconstruction')(formData);
        btnProgressOff(getById('upload-model-save'));
        // @ts-ignore
        if (res?.data?.error !== 'NONE') {
            // @ts-ignore
            console.error(res?.data.error);
            showMessage('#errors-output', res?.data.error);
        } else {
            window.location.href = '?id='+res.data.reconstruction.reconstruction._id;
        }
    }

    async uploadItem() {
        let formData: CreateItem;
        const title = (getById('reconstruction-title') as HTMLInputElement).value;
        const description = (getById('reconstruction-description') as HTMLTextAreaElement).value;

        let isOk = true;
        if (!title.length) {
            showInvalidField('#reconstruction-title');
            isOk = false;
        }
        if(!document.querySelector('input[name=agreement]:checked')) {
            showInvalidField('#agreement');
            isOk = false;
        }
        const category = (document.querySelector('input[name=category]:checked') as HTMLInputElement)?.value
        if(!category) {
            showMessage('#errors-output', 'Please select a category');
            isOk = false;
        }
        if (!isOk) {
            return
        }

        if(! await this.validateModel()) {
            return;
        }

        const files = await this.uploadFiles(this.mapstarUI.profile.itemSizeLimit);
        if(!files) {
            return;
        }

        formData = {
            coordinates: {
                latitude: 0,
                longitude: 0,
                altitude: 0
            },
            title,
            description,
            preview: files.preview,
            file: files.file,
            category
        }
        const res = await functions.httpsCallable('CreateItem')(formData);
        btnProgressOff(getById('upload-model-save'));
        // @ts-ignore
        if (res?.data?.error !== 'NONE') {
            // @ts-ignore
            console.error(res?.data.error);
            showMessage('#errors-output', res?.data.error);
        } else {
            this.closeUploadModel();
            this.mapstarUI.readMyItems();
        }
    }

    private async uploadFiles(glbLimit) {
        const glbFile = (getById('glb-file-input') as HTMLInputElement).files[0];
        if (!glbFile) {
            showMessage('#errors-output', 'Please choose GLB file');
            return
        }
        if(glbFile.size > glbLimit) {
            showMessage('#errors-output', 'Max model size is ' + (glbLimit /1024 / 1024) + 'Mb');
            return
        }
        if (!glbFile.name.endsWith('.glb')) {
            showMessage('#errors-output', 'Only .glb model is allowed');
            return
        }
        let previewFile = (getById('preview-file-input') as HTMLInputElement).files[0];
        btnProgressOn(getById('upload-model-save'));

        const bar = this.createProgressBar();
        const totalBytes = glbFile.size;

        const storageRef = firebase.storage().ref();
        const glbExt = glbFile.name.substring(glbFile.name.lastIndexOf('.')+1);
        let ref = storageRef.child(this.mapstarUI.userId + '/' + uuidv4() + '.'+ glbExt);
        let glbUploadTask = ref.put(glbFile);
        await new Promise<void>((resolve, reject) => glbUploadTask.on('state_changed',
            snapshot => this.uploadProgressHandler(snapshot, totalBytes, bar), e => reject(e),
            () => {bar.animate((1.0), {duration: 0.0001}); resolve()}));
        const glbMetaData: FullMetadata = await ref.getMetadata();
        let glbUrl;
        try {
            glbUrl = await ref.getDownloadURL();
        } catch (e) {
            console.error(e);
            showMessage('#errors-output', e.toString());
            return;
        }
        const previewFileName = previewFile ? previewFile.name : 'autogenerated-preview'
        // const gs = snapshot.metadata.ref.toString();
        let previewExt = previewFile ? previewFile.name.substring(previewFile.name.lastIndexOf('.')+1) : 'png';
        ref = storageRef.child(this.mapstarUI.userId + '/' + uuidv4() + '.'+ previewExt);
        let previewData;
        if (previewFile) {
            previewData = previewFile;
        } else {
            const data = await fetch((document.querySelector('.gallery img') as HTMLImageElement).src).then(res => res.blob());
            previewData = (document.querySelector('.gallery img') as HTMLImageElement)?.src ? data : null;
        }
        if (!previewData) {
            showMessage('#errors-output', 'Please choose preview JPG file');
            btnProgressOff(getById('upload-model-save'));
            return
        }
        await ref.put(previewData);
        const previewMetaData: FullMetadata = await ref.getMetadata();
        let previewUrl;
        try {
            previewUrl = await ref.getDownloadURL();
        } catch (e) {
            console.error(e);
            showMessage('#errors-output', e.toString());
            btnProgressOff(getById('upload-model-save'));
            return;
        }

        return {
            preview: {
                jpg: {
                    url: previewUrl,
                    fileName: previewMetaData.name,
                    origFileName: previewFileName,
                    size: previewMetaData.size,
                    created: Date.parse(previewMetaData.timeCreated),
                    lastUpdated: Date.parse(previewMetaData.updated)
                }
            },
            file: {
                url: glbUrl,
                fileName: glbMetaData.name,
                origFileName: glbFile.name,
                size: glbMetaData.size,
                created: Date.parse(glbMetaData.timeCreated),
                lastUpdated: Date.parse(glbMetaData.updated)
            }
        }
    }

    private uploadProgressHandler(snapshot: firebase.storage.UploadTaskSnapshot, totalBytes, bar: ProgressBar.Line) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const currentBytes = snapshot.bytesTransferred;
        let progress = (currentBytes / totalBytes);
        console.log('Upload is ' + progress * 100 + '% done');
        bar.animate((progress), {duration: 0.0001});
    }

    private createProgressBar(): ProgressBar.Line {
        getById('upload-progress').innerHTML = '';
        return new ProgressBar.Line('#upload-progress', {
            strokeWidth: 1,
            easing: 'easeInOut',
            duration: 1400,
            color: '#18F7FE',
            trailColor: '#eee',
            trailWidth: 1,
            svgStyle: {width: '100%', height: '100%'},
            text: {
                style: {
                    // Text color.
                    // Default: same as stroke color (options.color)
                    color: '#18F7FE',
                    margin: '0 auto',
                    textAlign: 'center',
                },
                autoStyleContainer: false
            },
            from: {color: '#18F7FE'},
            to: {color: '#18F7FE'},
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * 100) + ' %');
            }
        })
    }

    private createDraggableMap() {
        mapboxgl.accessToken = config.mapboxToken;
        this.map = new mapboxgl.Map({
            container: 'map-draggable',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [8.432844798291853,49.00416996723621],
            zoom: 14,
        });
        this.map.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            })
        );
        this.map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                localGeocoder: coordinatesGeocoder,
                zoom: 4,
                placeholder: 'Try: -40, 170',
                mapboxgl: mapboxgl
            })
        );

        const marker = new mapboxgl.Marker({
            // draggable: true
        }).setLngLat([0, 0])
            .addTo(this.map);

        // marker.on('dragend', onDragEnd);
        const movestart = () => marker.setLngLat(this.map.getCenter());
        const move = () => marker.setLngLat(this.map.getCenter());
        const moveend = () => {
            marker.setLngLat(this.map.getCenter());
            var lngLat = marker.getLngLat();
            (getById('latitude') as HTMLInputElement).value = lngLat?.lat;
            (getById('longitude') as HTMLInputElement).value = lngLat?.lng;
        }
        const load = () => this.map.resize();

        this.map.on('movestart', movestart);
        this.map.on('move', move);
        this.map.on('moveend', moveend);
        this.map.on('load', load);

        document.addEventListener('detach', () => {
            this.map.off('movestart', movestart);
            this.map.off('move', move);
            this.map.off('moveend', moveend);
            this.map.off('load', load);
        });

        [getById('latitude'), getById('longitude')].forEach(n => n.onchange = () => {
            const lat = (getById('longitude') as HTMLInputElement).value;
            const long = (getById('latitude') as HTMLInputElement).value
            marker.setLngLat([long, lat]);
            this.map.panTo([lat, long]);
        });

        /* Given a query in the form "lng, lat" or "lat, lng"
        * returns the matching geographic coordinate(s)
        * as search results in carmen geojson format,
        * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
        function coordinatesGeocoder(query) {
            // Match anything which looks like
            // decimal degrees coordinate pair.
            var matches = query.match(
                /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
            );
            if (!matches) {
                return null;
            }

            function coordinateFeature(lng, lat) {
                return {
                    center: [lng, lat],
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat]
                    },
                    place_name: 'Lat: ' + lat + ' Lng: ' + lng,
                    place_type: ['coordinate'],
                    properties: {},
                    type: 'Feature'
                };
            }

            var coord1 = Number(matches[1]);
            var coord2 = Number(matches[2]);
            var geocodes = [];

            if (coord1 < -90 || coord1 > 90) {
            // must be lng, lat
                geocodes.push(coordinateFeature(coord1, coord2));
            }

            if (coord2 < -90 || coord2 > 90) {
            // must be lat, lng
                geocodes.push(coordinateFeature(coord2, coord1));
            }

            if (geocodes.length === 0) {
            // else could be either lng, lat or lat, lng
                geocodes.push(coordinateFeature(coord1, coord2));
                geocodes.push(coordinateFeature(coord2, coord1));
            }

            return geocodes;
        }
    }

    private removeMap() {
        const ev = new CustomEvent('detach');
        document.dispatchEvent(ev);
        getById('gps-row').remove();
    }

    private openValidationTab(validationResult: BABYLON.GLTF2.IGLTFValidationResults) {
        const tab = window.open("", "_blank");
        if (tab) {
            tab.document.title = "glTF Validation Results";
            tab.document.body.innerText = JSON.stringify(validationResult, null, 2);
            tab.document.body.style.whiteSpace = "pre";
            tab.document.body.style.fontFamily = "monospace";
            tab.document.body.style.fontSize = "14px";
            tab.focus();
        }
    }

    private async validateModel() {
        const glbFile = (getById('glb-file-input') as HTMLInputElement).files[0];
        const validationResult = await BABYLON.GLTFValidation.ValidateAsync(await glbFile.arrayBuffer(), "file://", "", (uri) => glbFile.arrayBuffer())
        console.log(validationResult?.issues?.numErrors);
        if(validationResult?.issues?.numErrors) {
            showMessage('#errors-output', 'Model has errors. Click for <a>More details</a>');
            getById('errors-output').onclick = () => {this.openValidationTab(validationResult)}
            this.disableSaveBtn();
            return false;
        }
        this.enableSaveBtn();
        return true;
    }

    private disableSaveBtn() {
        document.querySelector(".upload-model button.save").setAttribute("disabled", "disabled");
    }

    private enableSaveBtn() {
        document.querySelector(".upload-model button.save").removeAttribute("disabled");
    }

}
