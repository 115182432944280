import {ReconstructionItem} from "./createReconstruction";

export class newItem implements ReconstructionItem {
    _id = String(Math.round(Math.random()*1000000000));
    contentType = '';
    content = {};
    position = {x: 0, y: 0, z: 0};
    rotation = {x: 0, y: 0, z: 0};
    scale = {x: 1, y: 1, z: 1};
}

export class VideoItem extends newItem {
    contentType = 'video';
    content = {videoType: 'youtube', videoId: ''}
}
export class ImageItem extends newItem {
    contentType = 'image';
    content = { url: '/Assets/img/layer-preview.jpg', description: ''}
}
export class AudioItem extends newItem {
    contentType = 'audio';
    content = { url: '/Assets/pipe9.mp3', description: ''}
}
export class TextItem extends newItem {
    contentType = 'text';
    content = {text: ''}
}
export class LinkItem extends newItem {
    contentType = 'url';
    content = {url: '', title: ''}
}
export class ModelItem extends newItem {
    contentType = 'model';
    content = {shortCode: ''}
}
export class UserItem extends newItem {
    contentType = 'custom';
    content = {
        itemId: '',
        userId: '',
        preview: '',
        file: '',
        title: '',
        description: '',
        category: '',
        license: '',
    }}
