export const itemCategories = {
    "aerospace"               : {title: "Aerospace", icon: "space-shuttle"},
    "art"                     : {title: "Art", icon: "pencil-paintbrush"},
    "architecture"            : {title: "Architecture", icon: "building"},
    "avatar"                  : {title: "Avatar", icon: "user-astronaut"},
    "blocks"                  : {title: "Blocks", icon: "th-large"},
    "car"                     : {title: "Car", icon: "cars"},
    "comedy"                  : {title: "Comedy", icon: "theater-masks"},
    "currentEvents"           : {title: "Current Events", icon: "calendar-star"},
    "education"               : {title: "Education", icon: "university"},
    "entertainment"           : {title: "Entertainment", icon: "microphone-stand"},
    "food"                    : {title: "Food", icon: "french-fries"},
    "furniture"               : {title: "Furniture", icon: "loveseat"},
    "gaming"                  : {title: "Gaming", icon: "gamepad-alt"},
    "howto"                   : {title: "HowTo & Style", icon: "film-alt"},
    "movieAndAnimation"       : {title: "Movie & Animation", icon: "camera-movie"},
    "music"                   : {title: "Music", icon: "music-alt"},
    "newsAndPolitics"         : {title: "News & Politics", icon: "newspaper"},
    "petsAndAnimals"          : {title: "Pets & Animals", icon: "ram"},
    "sports"                  : {title: "Sports", icon: "basketball-ball"},
    "scienceAndTechnology"    : {title: "Science & Technology", icon: "flask"},
    "tiltbrushModel"          : {title: "Tiltbrush Model", icon: "paint-brush"},
    "travelAndEvents"         : {title: "Travel & Events", icon: "suitcase"},
    "other"                   : {title: "Other", icon: "feather-alt"},
}
