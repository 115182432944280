import * as BABYLON from 'babylonjs';
import * as GUI from "babylonjs-gui";
import * as YTR from "./youtube-renderer";
import {CSV} from "./csv";
import {ReconstructionItem} from "./type/createReconstruction";
import {UserItem} from "./type/newItem";
import {getById, hide, show} from "./helpers";
import {config} from "./config";

export class ItemsLibrary {
    scene: BABYLON.Scene;
    glbRoot;
    engine;
    blankWidth = 0.5*0.8;
    blankHeight = 0.5*0.8;
    blankDepth = 0.005*0.8;
    css3dRenderer;
    anotherYoutubeRenderedVideos = [];
    itemEntries: ItemEntry[] = [];
    isEditMode = false;
    dontOpenModal = false;
    gizmoManager: BABYLON.GizmoManager;
    activeItem: BABYLON.Mesh = null;
    onItemEdit: CallableFunction = (item) => {};

    constructor(scene, glbRoot, engine) {
        this.scene = scene;
        this.glbRoot = glbRoot;
        this.engine = engine;
    }

    createTextObject(item, attach = false) {
        const url = 'Assets/glb/TextItemLEFT_2.glb';
        BABYLON.SceneLoader.LoadAssetContainer("", url, this.scene,  container => {
            var meshes = container.meshes;
            const parent: BABYLON.Mesh = meshes[0].getChildMeshes(true, m => m.id === 'Text_Item')[0] as BABYLON.Mesh;
            parent.scaling.x = Math.abs(parent.scaling.x);
            parent.rotation = new BABYLON.Vector3(-Math.PI/2, 0,0);
            const model = meshes[0].getChildMeshes()[0].getChildMeshes()[0];
            const textMaterial = createTextMaterial.call(this, model);
            const rootMesh = this.processRootMesh(item, meshes);

            model.material = textMaterial;
            // Adds all elements to the scene
            container.addAllToScene();
            this.attachItems();
            attach && this.gizmoManager.attachToMesh(rootMesh);
        })
        // TODO use unified function
        function createTextMaterial(model) {
            const width = -model.getBoundingInfo().boundingBox.vectors[0].x + model.getBoundingInfo().boundingBox.vectors[1].x;
            const height = -model.getBoundingInfo().boundingBox.vectors[0].y + model.getBoundingInfo().boundingBox.vectors[1].y;
            const k = 10; // how the hell to fit material texture into mesh?!
            const advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(model, width * k, height * k);
            const textMaterial = new BABYLON.StandardMaterial("textMaterial", this.scene);

            let text = item.content.text ?? '';
            let k1 = 1, k2 = 1;
            const ctx = advancedTexture.getContext();
            let textPrepared = text;

            //dirty hack for stoned Safari
            const userAgentString = navigator.userAgent;
            // Detect Chrome
            let chromeAgent = userAgentString.indexOf("Chrome") > -1;
            // Detect Safari
            let safariAgent = userAgentString.indexOf("Safari") > -1;
            // Discard Safari since it also matches Chrome
            if ((chromeAgent) && (safariAgent)) safariAgent = false;
            if(safariAgent){
                k1 = 0.4;
                k2 = 0;
            }

            let fontSizePx = 38*k1;
            const textureWidthPx = width * k;
            const text1 = new GUI.TextBlock();
            text1.widthInPixels = textureWidthPx;
            text1.color = "black";
            text1.fontSize = fontSizePx+"px Arial";
            text1.textWrapping = GUI.TextWrapping.WordWrap;
            text1.textHorizontalAlignment = GUI.TextBlock.HORIZONTAL_ALIGNMENT_LEFT;
            text1.textVerticalAlignment = GUI.TextBlock.VERTICAL_ALIGNMENT_TOP;
            text1.paddingLeft = text1.paddingRight = 20*k2;
            text1.paddingTop = text1.paddingBottom = 20;
            text1.text = textPrepared;

            while((text1.computeExpectedHeight() > height * k) && (fontSizePx > 8)) {
                text1.fontSize = (--fontSizePx)+"px Arial";
            }
            advancedTexture.addControl(text1);
            advancedTexture.background = '#FFFFFF';
            advancedTexture.uAng = Math.PI;
            // textMaterial.diffuseTexture = advancedTexture;
            textMaterial.emissiveTexture = advancedTexture;
            textMaterial.disableLighting = true;
            return textMaterial;
        }
    }

    createImageObject(item, attach = false) {
        const url = 'Assets/glb/ImagetItem_MiddleLEFT.glb';
        const imgUrl = item.content.url;
        BABYLON.SceneLoader.LoadAssetContainer("", url, this.scene,  container => {
            const meshes = container.meshes;
            const model = meshes[1];
            const parent = meshes[2];
            parent.scaling.z = -Math.abs(parent.scaling.z);
            const rootMesh = this.processRootMesh(item, meshes);
            this.drawImage(model as BABYLON.Mesh, imgUrl);

            container.addAllToScene();
            this.attachItems();
            attach && this.gizmoManager.attachToMesh(rootMesh);
        })
    }

    createLinkObject(item, attach = false) {
        var self = this;
        const mainUrl = 'Assets/glb/LinkItem.glb';
        const infoUrl = 'Assets/glb/LinkItem_Sign_Beat_round.glb';
        let modelsToLoad = 2;
        const assetsManager = new BABYLON.AssetsManager(this.scene);
        const parentContainer = new BABYLON.TransformNode('LinkItem', this.scene);
        const wrapper = new BABYLON.TransformNode('ItemsWrapper', this.scene);
        wrapper.parent = parentContainer;
        const mainTask = assetsManager.addMeshTask('linkMain', '', '', mainUrl);
        mainTask.onSuccess = (task) => {
            task.loadedMeshes[0].parent = wrapper;
            task.loadedMeshes[0].rotationQuaternion.y = 0; // why is 1 by default?
            !--modelsToLoad && process([parentContainer]);
        }
        const infoTask = assetsManager.addMeshTask('linkInfo', '', '', infoUrl);
        infoTask.onSuccess = (task) => {
            task.loadedMeshes[0].parent = wrapper;
            task.loadedMeshes[0].rotationQuaternion.y = 0; // why is 1 by default?
            task.loadedMeshes[0].position.y = 0.3;
            const text = truncate(item?.content?.title || '', 25);
            const mesh = task.loadedMeshes.find(m => m.name === 'Sign5_Content.001');
            self.createTextMaterial(mesh, text, 100, 80, 30, 5, 72, Math.PI, 3 / 2 * Math.PI, -0.38)
            !-- modelsToLoad && process([parentContainer]);
        }
        assetsManager.load();

        function process(meshes) {
            const rootMesh = self.processRootMesh(item, meshes);
            self.attachItems();
            attach && self.gizmoManager.attachToMesh(rootMesh);
            if (item.content.url.length > 0) {
                const am = new BABYLON.ActionManager(self.scene);
                const action = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (event) => {
                    if(self.isEditMode) { return }
                    const url = item.content.url.startsWith('https://') ? item.content.url : 'https://' + item.content.url;
                    window.open(url);
                });
                rootMesh.getChildMeshes(false).forEach(m => {
                    m.actionManager = am;
                    m.actionManager.registerAction(action);
                })
            } else {console.warn('NO URL PROVIDED')}
        }
    }

    createAudioObject(item, attach = false) {
        const music = new BABYLON.Sound("Audio_"+item._id, item.content.url, this.scene, () => {},
            {spatialSound: true, skipCodecCheck: true});//skipCodecCheck: https://github.com/BabylonJS/Babylon.js/issues/6508
        var self = this;
        const mainUrl = 'Assets/glb/AudioItem_1811_alternative2.glb';
        const infoUrl = 'Assets/glb/LinkItem_Sign_Beat_round.glb';
        let modelsToLoad = 2;
        const assetsManager = new BABYLON.AssetsManager(this.scene);
        const parentContainer = new BABYLON.TransformNode('AudioItem', this.scene);
        const wrapper = new BABYLON.TransformNode('ItemsWrapper', this.scene);
        wrapper.parent = parentContainer;
        const mainTask = assetsManager.addMeshTask('AudioMain', '', '', mainUrl);
        mainTask.onSuccess = (task) => {
            task.loadedMeshes[0].parent = wrapper;
            task.loadedMeshes[0].name = 'AudioMain';
            task.loadedMeshes[0].rotationQuaternion.y = 0; // why is 1 by default?
            !--modelsToLoad && process([parentContainer]);
        }
        const infoTask = assetsManager.addMeshTask('AudioInfo', '', '', infoUrl);
        infoTask.onSuccess = (task) => {
            task.loadedMeshes[0].parent = wrapper;
            task.loadedMeshes[0].name = 'AudioInfo';
            task.loadedMeshes[0].rotationQuaternion.y = 0; // why is 1 by default?
            task.loadedMeshes[0].position.y = 0.7;
            const text = truncate(item?.content?.description || '', 25);
            const mesh = task.loadedMeshes.find(m => m.name === 'Sign5_Content.001');
            self.createTextMaterial(mesh, text, 100, 80, 30, 5, 72, Math.PI, 3 / 2 * Math.PI, -0.38)
            !-- modelsToLoad && process([parentContainer]);
        }
        assetsManager.load();

        function process(meshes: BABYLON.TransformNode[]) {
            const container = meshes[0];
            const model: BABYLON.Mesh = (container.getChildren(() => true, false).find(m => m.name === 'AudioMain') as BABYLON.Mesh);
            const modelMeshes = model.getChildMeshes();
            (model as BABYLON.Mesh).overrideMaterialSideOrientation = 1;

            const rootMesh = self.processRootMesh(item, meshes);

            self.attachItems();
            attach && self.gizmoManager.attachToMesh(rootMesh);

            const actionPlay = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (event) => { play() });
            modelMeshes[4].actionManager = new BABYLON.ActionManager(self.scene);
            modelMeshes[4].actionManager.registerAction(actionPlay);
            modelMeshes[5].actionManager = new BABYLON.ActionManager(self.scene);
            modelMeshes[5].actionManager.registerAction(actionPlay);

            const actionPause = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (event) => { pause() });
            modelMeshes[2].actionManager = new BABYLON.ActionManager(self.scene);
            modelMeshes[2].actionManager.registerAction(actionPause);
            modelMeshes[3].actionManager = new BABYLON.ActionManager(self.scene);
            modelMeshes[3].actionManager.registerAction(actionPause);

            const actionReplay = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (event) => { replay() });
            modelMeshes[6].actionManager = new BABYLON.ActionManager(self.scene);
            modelMeshes[6].actionManager.registerAction(actionReplay);
            modelMeshes[7].actionManager = new BABYLON.ActionManager(self.scene);
            modelMeshes[7].actionManager.registerAction(actionReplay);
        }


        function play() {
            if(self.isEditMode) return;
            BABYLON.Engine.audioEngine.onAudioUnlockedObservable.add(() => {
                music.play();
                BABYLON.Engine.audioEngine.onAudioUnlockedObservable.clear()
            });

            if(!music.isPlaying) {
                if (!music.isPaused) { // somehow this fixes Safari bug
                    music.stop();
                }
                music.play();
            }
        }
        function pause() {
            if(self.isEditMode) return;
            music.pause();
        }

        function replay() {
            if(self.isEditMode) return;
            if (music.isPaused) {
                music.play(); // can't stop paused sound
            }
            music.stop();
            music.play();
        }
    }

    createYoutubeObject(item, attach = false) {
        const url = 'Assets/glb/VideoItem_Account_6.glb';
        BABYLON.SceneLoader.LoadAssetContainer("", url, this.scene,  container => {
            const meshes = container.meshes;
            const rootMesh: BABYLON.Mesh = this.processRootMesh(item, meshes);
            const videoItem = rootMesh.getChildMeshes(true, m => m.name === 'Video_Item.001')[0];
            videoItem.scaling.z = -Math.abs(videoItem.scaling.z);
            const videoId = item.content.videoId;
            const contentMesh = rootMesh.getChildMeshes(false, m => m.id === 'Video_Item_Content.001')[0];
            this.prepareYoutubeModel(videoId, contentMesh, rootMesh);

            // Adds all elements to the scene
            container.addAllToScene();
            this.attachItems();
            attach && this.gizmoManager.attachToMesh(rootMesh);
        })

    }

    private createModelObject(item, attach = false, url) {
        if(!url) {
            console.warn('Missing url for model found!');
            return;
        }
        show(getById('spinner'));
        BABYLON.SceneLoader.LoadAssetContainer("", url, this.scene,  container => {
            const meshes = container.meshes;
            const rootMesh = this.processRootMesh(item, meshes);
            const wrap = new BABYLON.TransformNode('mirror-wrap', this.scene);
            rootMesh.getChildTransformNodes( true, m => true).forEach(m => m.parent = wrap);
            wrap.parent = rootMesh;
            wrap.scaling.z = -Math.abs(wrap.scaling.z);

            container.addAllToScene();
            this.attachItems();
            attach && this.gizmoManager.attachToMesh(rootMesh);
            hide(getById('spinner'));
        }, null, () => {hide(getById('spinner'))});
    }

    createCommonModel(item: ReconstructionItem, attach = false) {
        const shortCode = item.content.shortCode;
        this.getModelUrl(shortCode).then((url: string) => {
            this.createModelObject(item, attach, url);
        });
    }

    createUserModel(item: UserItem, attach = false) {
        this.createModelObject(item, attach, item.content.file);
    }

    degToRad(angleDegree) {
        return (Math.PI*2)/360 * angleDegree;
    }

    radToDeg(angleRad) {
        return 180/Math.PI * angleRad;
    }

    updateTextItem(text, itemId){
        const entry = this.getEntryByItemId(itemId);
        const i = this.itemEntries.findIndex(e => e.id === itemId);
        this.itemEntries[i].item.content.text = text;

        let model = this.setPositioning(i);
        model.dispose();
        this.dontOpenModal = true;
        this.createTextObject(entry.item, true);
    }
    updateLinkItem(text, title, itemId){
        const entry = this.getEntryByItemId(itemId);
        const i = this.itemEntries.findIndex(e => e.id === itemId);
        this.itemEntries[i].item.content.url = text;
        this.itemEntries[i].item.content.title = title;

        let model = this.setPositioning(i);
        model.dispose();
        this.dontOpenModal = true;
        this.createLinkObject(entry.item, true);
    }
    updateYoutubeItem(videoId, itemId){
        const entry = this.getEntryByItemId(itemId);
        const i = this.itemEntries.findIndex(e => e.id === itemId);
        this.itemEntries[i].item.content.videoId = videoId;

        let model = this.setPositioning(i);
        model.dispose();
        this.dontOpenModal = true;
        this.createYoutubeObject(entry.item, true);
    }
    updateImageItem(imageUrl, descr, itemId){
        const entry = this.getEntryByItemId(itemId);
        const i = this.itemEntries.findIndex(e => e.id === itemId);
        this.itemEntries[i].item.content.url = imageUrl;
        this.itemEntries[i].item.content.description = descr;

        let model = this.setPositioning(i);
        model.dispose();
        this.dontOpenModal = true;
        this.createImageObject(entry.item, true);
    }
    updateAudioItem(audioUrl, descr,  itemId){
        const entry = this.getEntryByItemId(itemId);
        const i = this.itemEntries.findIndex(e => e.id === itemId);
        this.itemEntries[i].item.content.url = audioUrl;
        this.itemEntries[i].item.content.description = descr;

        let model = this.setPositioning(i);
        model.dispose();
        this.dontOpenModal = true;
        this.createAudioObject(entry.item, true);
    }
    enterEditMode() {
        this.isEditMode = true;
        if(!this.gizmoManager) {
            this.gizmoManager = new BABYLON.GizmoManager(this.scene);
        }
        this.gizmoManager.boundingBoxGizmoEnabled = true;
        this.attachItems();
        this.gizmoManager.clearGizmoOnEmptyPointerEvent = true;
        this.gizmoManager.gizmos.boundingBoxGizmo.setEnabledScaling(true, true);
        this.gizmoManager.gizmos.boundingBoxGizmo.fixedDragMeshScreenSize = true;

        this.gizmoManager.onAttachedToMeshObservable.add((e: BABYLON.Mesh) => {
            if(!this.isEditMode) { return }
            this.activeItem = e;
            if(!e){
                document.querySelectorAll('.menu-modal').forEach(el => el.classList.add('hidden'))
                document.querySelector('#removeMenu')?.classList?.add('hidden');
                document.querySelector('#root').classList.remove('modal-opened');
                return;
            }
            this.gizmoManager.gizmos.boundingBoxGizmo.setEnabledRotationAxis( e.id.startsWith('item-video') ? 'y' : 'xyz' );
            const entry = this.getEntryByModelId(e.id);
            if (!this.dontOpenModal) {
                this.onItemEdit(entry.item);
            }
            this.dontOpenModal = false;
            document.getElementById('removeMenu').classList.remove('hidden');
        });

        this.clearVideos();
        this.removeYoutubeCssRenderer();
        document.getElementById('css-youtube-container')?.remove();
        this.css3dRenderer = null;
    }
    exitEditMode() {
        this.isEditMode = false;
        this.gizmoManager.boundingBoxGizmoEnabled = false;
        this.gizmoManager.attachableMeshes = null;
        this.gizmoManager = null;
        document.querySelectorAll('.menu-modal').forEach(el => el.classList.add('hidden'))
    }

    prepareForSaving(): ReconstructionItem[] {
        return this.itemEntries.map((entry, i) => {
            const item: ReconstructionItem = JSON.parse(JSON.stringify(entry.item));
            let model: BABYLON.Mesh = (this.scene.getNodeByID(entry.modelId) as BABYLON.Mesh);
            if(!model) {
                console.warn('Missing model during saving!');
                return
            }
            let position = model.position;
            let rotation;
            if (model.rotationQuaternion) {
                const r = model.rotationQuaternion.toEulerAngles();
                rotation = new BABYLON.Vector3(this.radToDeg(r.x), this.radToDeg(r.y), this.radToDeg(r.z));
                if(item.contentType === 'video') {
                    model.rotationQuaternion = new BABYLON.Vector3(0, r.y, 0).toQuaternion();
                }
            } else {
                rotation = new BABYLON.Vector3(this.radToDeg(model.rotation.x), this.radToDeg(model.rotation.y), this.radToDeg(model.rotation.z));
                if(item.contentType === 'video') {
                    rotation.x = rotation.z = 0;
                    model.rotation = rotation;
                }
            }
            let scale = model.scaling;
            scale.x = Math.abs(model.scaling.x);
            scale.y = Math.abs(model.scaling.y);
            scale.z = Math.abs(model.scaling.z);

            item.position = {x: position._x, y: position._y, z: position._z};
            item.rotation = {x: rotation._x, y: rotation._y, z: rotation._z};
            item.scale = {x: Math.abs(scale._x), y: Math.abs(scale._y), z: Math.abs(scale._z)};
            delete item.rotationQuaternion;
            return item;
        })

    }

    initialItemPrepare(item: ReconstructionItem) {
        delete item.rotationQuaternion;
        item.rotation = new BABYLON.Vector3(this.degToRad(item.rotation.x),this.degToRad(item.rotation.y),this.degToRad(item.rotation.z));
        item.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(item.rotation.x, item.rotation.y, item.rotation.z);
        item.rotation = new BABYLON.Vector3(0,0,0);
        return item;
    }

    removeItem() {
        if(this.activeItem) {
            const entry = this.getEntryByModelId(this.activeItem.id);
            this.activeItem.dispose();
            const index = this.itemEntries.findIndex(e => e.modelId === this.activeItem.id);
            this.itemEntries.splice(index, 1);
            this.attachItems();
            this.gizmoManager.attachToMesh(null);
        }
    }

    private drawImage(model: BABYLON.Mesh, imgUrl) {
        const base = 512;
        const size = {width: base, height: base};

        const imageMaterial = new BABYLON.StandardMaterial('imageMaterial', this.scene);
        const dynamicTexture = new BABYLON.DynamicTexture("imageTexture", size, this.scene, false);
        dynamicTexture.uOffset = 0.08;
        dynamicTexture.uScale = 1.760;
        imageMaterial.diffuseTexture = dynamicTexture;
        const context = dynamicTexture.getContext();
        model.material = imageMaterial;

        try {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = imgUrl;
            const self = this;
            img.onload = function (event) {
                const image = event.target as HTMLImageElement;
                const parentWidth = size.width;
                const parentHeight = size.height;
                const imageWidth = image.width;
                const imageHeight = image.height;
                let {x, y, width, height} = self.cover(parentWidth, parentHeight, imageWidth, imageHeight);
                context.drawImage(image, x, y, width, height)
                // dynamicTexture.wAng = Math.PI;
                dynamicTexture.uAng = Math.PI;
                dynamicTexture.update(true);
            }
            img.onerror = function (err) {
                console.error('Error while loading image', err);
            }
        } catch (err) {
            console.error('Error while loading image', err);
        }
    }

    private prepareYoutubeModel(videoId, contentMesh, rootMesh: BABYLON.Mesh) {
        this.drawImageOnFace(contentMesh, 'Assets/img/pT7dAaRpc.png', { width: 512, height: 512/16*9 }, '#000');
        var self = this;
        function drawYoutubeData() {
            const channel = rootMesh.getChildMeshes(false, n => n.name === 'Title_Content1')[0];
            const title = rootMesh.getChildMeshes(false, n => n.name === 'Title_Content2')[0];
            const avatar = rootMesh.getChildMeshes(false, n => n.name === 'Account_Content')[0];


            const {YoutubeDataAPI} = require("youtube-v3-api")
            const api = new YoutubeDataAPI(config.youTubeApiKey);
            api.searchVideo(videoId).then(data => {
                console.log(data);
                const channelText = data.items?.[0]?.snippet?.channelTitle || '';
                channel.material = self.createTextMaterial(channel, channelText, 100, 10, 10, 5, 72, Math.PI, 3 / 2 * Math.PI);
                const titleText = data.items?.[0]?.snippet?.title || '';
                title.material = self.createTextMaterial(title, titleText, 100, 10, 10, 5, 72, Math.PI, 3 / 2 * Math.PI);
                const channelId = data.items?.[0]?.snippet?.channelId;
                if (channelId) {
                    api.searchChannel(channelId).then(channelData => {
                        console.log(channelData);
                        const channelThumb = data.items?.[0]?.snippet?.thumbnails?.medium?.url;
                        //TODO fix fcking CORS
                        // TODO drawImage
                        channelThumb && self.drawImageOnFace(avatar, channelThumb, {
                            width: 512,
                            height: 512 / 16 * 9
                        }, '#000');
                    })
                }
            })
        }

        drawYoutubeData();
        //remove itself
        //TODO refactor
        this.anotherYoutubeRenderedVideos.splice(this.anotherYoutubeRenderedVideos.findIndex(v => v.videoId === videoId), 1)
        const center = contentMesh.getBoundingInfo().boundingBox.centerWorld;
        // Setup the CSS renderer and Youtube object
        contentMesh.actionManager = new BABYLON.ActionManager(this.scene);
        const action = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
            (event) => {
                if (this.isEditMode) { return }
                const context = new AudioContext();
                const scene = this.scene;

                this.removeYoutubeCssRenderer();
                context.resume().then(() => {
                    const plane = BABYLON.MeshBuilder.CreateBox('youtubeBox-' + videoId,
                        {width: 0.325, height: 0.325, depth: this.blankDepth}, scene);
                    plane.parent = this.glbRoot;
                    plane.position = center;
                    // plane.rotation = this.convertQuaternionToRotation(rootMesh).rotation;
                    if(rootMesh.rotationQuaternion) {
                        // plane.rotationQuaternion = rootMesh.rotationQuaternion;
                        const r = rootMesh.rotationQuaternion.toEulerAngles();
                        plane.rotation = new BABYLON.Vector3(r.x, r.y, r.z);
                    } else {
                        plane.rotation = rootMesh.rotation;
                    }
                    // plane.rotation.y += Math.PI;

                    plane.scaling.x = rootMesh.scaling.x * 16 / 9;
                    plane.scaling.y = rootMesh.scaling.y;
                    plane.scaling.z = rootMesh.scaling.z;

                    // stop another instances and set to preview
                    this.clearVideos();

                    // https://playground.babylonjs.com/#1DX9UE#93
                    this.setupRenderer();
                    YTR.YoutubeRenderer.createCSSobject(plane, scene, videoId, this.css3dRenderer);
                    YTR.YoutubeRenderer.createMaskingScreen(plane, scene, this.engine);
                    // plane.actionManager.unregisterAction(action);

                    this.anotherYoutubeRenderedVideos.push({videoId, contentMesh, rootMesh, plane});
                });
            })
        contentMesh.actionManager.registerAction(action);
    }

    private isSafari() {
        //dirty hack for stoned Safari
        const userAgentString = navigator.userAgent;
        // Detect Chrome
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        // Detect Safari
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        // Discard Safari since it also matches Chrome
        if ((chromeAgent) && (safariAgent)) safariAgent = false;
        return safariAgent;
    }

    clearVideos() {
        this.anotherYoutubeRenderedVideos.forEach(video => {
            YTR.YoutubeRenderer.removeCssObject(video.videoId, this.scene);
            video.plane.dispose();
            this.prepareYoutubeModel(video.videoId, video.contentMesh, video.rootMesh);
        })
    }

    private getModelUrl(shortCode) {
        return new Promise((resolve, reject) => fetch('Assets/ModelItemsList.csv').then(req => {
                req.text().then(lines => {
                    const linksArray = CSV.parse(lines, null);
                    linksArray.unshift(0);
                    const found = linksArray.filter(line => line[0] === shortCode);
                    if (found.length) {
                        resolve(found.shift()[1]);
                    } else {
                        reject();
                    }
                })
            })
        )
    }

    private drawImageOnFace(blank, url, size, bgColor, flip = false, invert = false) {
        const imageMaterial = new BABYLON.StandardMaterial('imageMaterial', this.scene);
        // const dynamicTexture = new BABYLON.DynamicTexture("imageTexture", size, this.scene, false);
        const texture = new BABYLON.Texture(url, this.scene)
        texture.vAng = Math.PI;
        // imageMaterial.diffuseTexture = dynamicTexture;
        imageMaterial.diffuseTexture = texture;
        // imageMaterial.backFaceCulling = false;
        // const context = dynamicTexture.getContext();
        // dynamicTexture.wrapR = 2;
        texture.uOffset = -0.010;
        texture.vOffset = 0;
        texture.uScale = 1.015;
        texture.vScale = 1.790;
        blank.material = imageMaterial;
    }

    private fit(contains) {
        return function (parentWidth, parentHeight, childWidth, childHeight) {
            var doRatio = childWidth / childHeight;
            var cRatio = parentWidth / parentHeight;
            var width = parentWidth;
            var height = parentHeight;

            if (contains ? (doRatio > cRatio) : (doRatio < cRatio)) {
                height = width / doRatio;
            } else {
                width = height * doRatio;
            }

            return {
                width: width,
                height: height,
                x: (parentWidth - width) / 2,
                y: (parentHeight - height) / 2
            };
        };
    }
    // functions to emulate css property background-cover
    private contain = this.fit(true);
    private cover = this.fit(false);

    private removeYoutubeCssRenderer() {
        this.scene.onBeforeRenderObservable.clear();
    }
    private createTextMaterial(model, text, width, height, paddingH, paddingV, fontSize, vAng = Math.PI, wAng = 3/2*Math.PI, vOffset = 0) {
        const k = 10; // how the hell to fit material texture into mesh?!
        const advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(model, width * k, height * k);
        const textMaterial = new BABYLON.StandardMaterial("textMaterial", this.scene);

        let k1 = 1, k2 = 1;
        if(this.isSafari()){
            k1 = 0.4;
            k2 = 0;
        }

        let fontSizePx = fontSize*k1;
        const textureWidthPx = width * k;
        const text1 = new GUI.TextBlock();
        text1.widthInPixels = textureWidthPx;
        text1.color = "black";
        text1.fontSize = fontSizePx+"px Arial";
        text1.textWrapping = GUI.TextWrapping.WordWrap;
        text1.textHorizontalAlignment = GUI.TextBlock.HORIZONTAL_ALIGNMENT_LEFT;
        text1.textVerticalAlignment = GUI.TextBlock.VERTICAL_ALIGNMENT_CENTER;
        text1.paddingLeft = text1.paddingRight = paddingH*k2;
        text1.paddingTop = text1.paddingBottom = paddingV;
        text1.text = text;

        while((text1.computeExpectedHeight() > height * k) && (fontSizePx > 8)) {
            text1.fontSize = (--fontSizePx)+"px Arial";
        }
        advancedTexture.addControl(text1);
        advancedTexture.background = '#FFFFFF';
        // advancedTexture.uAng = Math.PI;
        advancedTexture.vAng = Math.PI;
        advancedTexture.wAng = 3/2*Math.PI;
        advancedTexture.vOffset = vOffset;
        // textMaterial.diffuseTexture = advancedTexture;
        textMaterial.emissiveTexture = advancedTexture;
        textMaterial.disableLighting = true;
        return textMaterial;
    }

    private processRootMesh(item, meshes): BABYLON.Mesh {
        const rootMesh = meshes[0];
        const blankPosition = new BABYLON.Vector3(item.position.x, item.position.y, item.position.z);
        // const blankRotation = new BABYLON.Vector3(item.rotation.x, item.rotation.y, item.rotation.z);
        const blankScaling = new BABYLON.Vector3(item.scale.x, item.scale.y, item.scale.z);

        rootMesh.id = 'item-'+item.contentType+'-' + item._id;
        rootMesh.parent = this.glbRoot;
        rootMesh.position = blankPosition;
        // rootMesh.rotation = blankRotation;
        if(!item.rotationQuaternion) {
            rootMesh.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(item.rotation.x, item.rotation.y, item.rotation.z);
            rootMesh.rotationQuaternion.w = 1;
        } else {
            rootMesh.rotationQuaternion = item.rotationQuaternion;
        }
        rootMesh.scaling = blankScaling;
        (!this.getEntryByItemId(item._id)) && this.itemEntries.push({id: item._id, modelId: rootMesh.id, item: item});

        return rootMesh;
    }
    // Youtube mesh renderer
    private setupRenderer () {
        let container = document.getElementById('css-youtube-container')
        let canvasZone = document.getElementById('canvasZone')
        if(!container) {
            container = document.createElement('div')
            container.id = 'css-youtube-container';
            container.style.position = 'absolute';
            container.style.width = '100%';
            container.style.height = '100%';

            container.style.zIndex = '-1';
            canvasZone.insertBefore(container, canvasZone.firstChild)
        }
        if (!this.css3dRenderer) {
            this.css3dRenderer = new YTR.CSS3DRenderer();
            container.appendChild(this.css3dRenderer.domElement)
            this.css3dRenderer.setSize(canvasZone.offsetWidth, canvasZone.offsetHeight)

            window.addEventListener('resize', e => {
                this.css3dRenderer.setSize(canvasZone.offsetWidth, canvasZone.offsetHeight)
            })
        }
    }

    private setPositioning(i: number) {
        let model = this.scene.getMeshByID(this.itemEntries[i].modelId) as BABYLON.Mesh;
        this.itemEntries[i].item.position = model.position;
        if (model.rotationQuaternion) {
            this.itemEntries[i].item.rotationQuaternion = model.rotationQuaternion;
            if(this.itemEntries[i].item.contentType === 'video') {
                this.itemEntries[i].item.rotationQuaternion.x = 0;
                this.itemEntries[i].item.rotationQuaternion.z = 0;
            }
        } else {
            this.itemEntries[i].item.rotation = model.rotation;
        }

        this.itemEntries[i].item.scale = model.scaling;
        this.itemEntries[i].item.scale.x = Math.abs(model.scaling.x);
        this.itemEntries[i].item.scale.y = Math.abs(model.scaling.y);
        this.itemEntries[i].item.scale.z = Math.abs(model.scaling.z);
        return model;
    }

    private attachItems() {
        this.gizmoManager && (this.gizmoManager.attachableMeshes = this.itemEntries.map(e => this.scene.getMeshByID(e.modelId)))
    }

    private getEntryByModelId(modelId): ItemEntry {
        return this.itemEntries.find(entry => entry.modelId === modelId);
    }

    private getEntryByItemId(itemId): ItemEntry {
        return this.itemEntries.find(entry => entry.id === itemId);
    }

    static normalizeItem(item: ReconstructionItem) {
        const normalized = JSON.parse(JSON.stringify(item));
        ['position', 'rotation', 'scale'].forEach(p => {
            if(item[p]) {
                normalized[p] = Object.fromEntries(Object.entries(item[p])
                  .map(pair => {pair[1] = Math.floor((pair[1] as number) * 1000000000) / 1000000000; return pair}))
            }
        })
        return normalized;
    }
}


export interface ItemEntry {
    modelId: string;
    id: string;
    item: ReconstructionItem;
}
function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}
