import * as BABYLON from 'babylonjs';
import {float} from "babylonjs/types";

export class YoutubeRenderer {

    static createCSSobject(mesh: BABYLON.Mesh, scene, videoID, renderer) {
        const width = 640
        const height = 360
        const transform = '';
        scene.onBeforeRenderObservable.add(() => {
            renderer.render(scene, scene.activeCamera)
        })


        const container = document.querySelector('#css-youtube-container > div > div');
        const wrap = document.createElement('div');
        wrap.id = 'youtube-wrap-video-' + videoID;
        wrap.style.backgroundColor = '#000';
        container.appendChild(wrap);

        const divId = 'youtube-video-' + videoID;
        const div = document.createElement('div')
        wrap.appendChild(div);
        div.id = divId;
        div.style.width = width + 'px'
        div.style.height = height + 'px'
        div.style.backgroundColor = '#000'

        // create iframe with given params and then bind new Player to existing iframe
        // because Player can't handle all necessary params
        var iframe = document.createElement('iframe')
        iframe.id = 'video-' + videoID
        iframe.style.width = width + 'px'
        iframe.style.height = height + 'px'
        iframe.style.border = '0px'
        iframe.allow = 'autoplay'
        iframe.src = ['https://www.youtube.com/embed/', videoID, '?feature=oembed&controls=0&hd=1&modestbranding=1&autohide=1&showinfo=0&enablejsapi=1&autoplay=1'].join('')
        div.appendChild(iframe)

        var player;

        function onYouTubeIframeAPIReady() {
            player = new YT.Player('video-' + videoID, {
                height: height,
                width: width,
                videoId: videoID,
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            });
        }

        onYouTubeIframeAPIReady();

        // 4. The API will call this function when the video player is ready.
        function onPlayerReady(event) {

            var CSSobject = new CSS3DObject(wrap, scene)
            // const scaling = mesh.scaling;
            // scaling.z = -scaling.z
            CSSobject.position.copyFrom(mesh.getAbsolutePosition())
            CSSobject.rotation.copyFrom(mesh.rotation);
            CSSobject.rotation.y = -mesh.rotation.y;
            CSSobject.scaling.copyFrom(mesh.scaling.multiplyByFloats(0.24, 0.32, 1));
            // object.scaling = new BABYLON.Vector3(0.89, 0.6666, 1);
            // object.scaling = object.scaling.multiplyByFloats(0.2, 0.1, 1);

            const player = event.target;
            const iframe = player.getIframe();
            iframe.style.transform = transform;
            // player.playVideo()
            mesh.actionManager = new BABYLON.ActionManager(scene);
            mesh.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                    function () {
                        let playerState = player.getPlayerState();
                        if (playerState === YT.PlayerState.ENDED) {
                            player.seekTo(0);
                        }
                        if (playerState === YT.PlayerState.PLAYING) {
                            player.pauseVideo();
                        } else {
                            player.playVideo();
                        }

                    })
            )
        }

        // 5. The API calls this function when the player's state changes.
        //    The function indicates that when playing a video (state=1),
        //    the player should play for six seconds and then stop.
        function onPlayerStateChange(event) {
        }

    }

    static createMaskingScreen(maskMesh, scene, engine) {
        let depthMask = new BABYLON.StandardMaterial('matDepthMask', scene)
        depthMask.backFaceCulling = false

        maskMesh.material = depthMask

        maskMesh.onBeforeRenderObservable.add(() => engine.setColorWrite(false))
        maskMesh.onAfterRenderObservable.add(() => engine.setColorWrite(true))

        // swap meshes to put mask first
        var mask_index = scene.meshes.indexOf(maskMesh)
        scene.meshes[mask_index] = scene.meshes[0]
        scene.meshes[0] = maskMesh
    }

    static removeCssObject(videoId, scene) {
        scene.onBeforeRenderObservable.clear();
        scene.meshes.forEach((m, i) => {
            if(m instanceof CSS3DObject) {
                scene.removeMesh(m)
            }
        });
        const videoEl = document.getElementById('youtube-wrap-video-'+videoId);
        if (videoEl) {
            videoEl.remove();
        }
    }

}

export class CSS3DObject extends BABYLON.Mesh {
    element;
    constructor(element, scene) {
        super('CSS3DObject')
        this.element = element
        this.element.style.position = 'absolute'
        this.element.style.pointerEvents = 'auto'
    }
}

export class CSS3DRenderer {
    cache;
    domElement;
    cameraElement;
    width;
    height;
    widthHalf;
    heightHalf;
    constructor() {
        var matrix = new BABYLON.Matrix()

        this.cache = {
            camera: { fov: 0, style: '' },
            objects: new WeakMap()
        }

        var domElement = document.createElement( 'div' )
        domElement.style.overflow = 'hidden'
        domElement.style.backgroundColor = '#000';

        this.domElement = domElement
        this.cameraElement = document.createElement( 'div' )
        this.cameraElement.classList.add('youtubeRendererCamera')
        this.cameraElement.style.webkitTransformStyle = 'preserve-3d'
        this.cameraElement.style.transformStyle = 'preserve-3d'
        this.cameraElement.style.pointerEvents = 'none'

        domElement.appendChild(this.cameraElement)
    }

    getSize() {
        return {
            width: this.width,
            height: this.height
        }
    }

    setSize(width, height) {
        this.width = width
        this.height = height
        this.widthHalf = this.width / 2
        this.heightHalf = this.height / 2

        this.domElement.style.width = width + 'px'
        this.domElement.style.height = height + 'px'

        this.cameraElement.style.width = width + 'px'
        this.cameraElement.style.height = height + 'px'
    }

    epsilon(value) {
        return Math.abs(value) < 1e-10 ? 0 : value
    }

    getCameraCSSMatrix(matrix) {
        var elements = matrix.m

        return 'matrix3d(' +
            this.epsilon( elements[ 0 ] ) + ',' +
            this.epsilon( - elements[ 1 ] ) + ',' +
            this.epsilon( elements[ 2 ] ) + ',' +
            this.epsilon( elements[ 3 ] ) + ',' +
            this.epsilon( elements[ 4 ] ) + ',' +
            this.epsilon( - elements[ 5 ] ) + ',' + //here
            this.epsilon( elements[ 6 ] ) + ',' +
            this.epsilon( elements[ 7 ] ) + ',' +
            this.epsilon( elements[ 8 ] ) + ',' +
            this.epsilon( - elements[ 9 ] ) + ',' +
            this.epsilon( elements[ 10 ] ) + ',' +
            this.epsilon( elements[ 11 ] ) + ',' +
            this.epsilon( elements[ 12 ] ) + ',' +
            this.epsilon( - elements[ 13 ] ) + ',' +
            this.epsilon( elements[ 14 ] ) + ',' +
            this.epsilon( elements[ 15 ] ) +
            ')'
    }

    getObjectCSSMatrix(matrix, cameraCSSMatrix) {
        var elements = matrix.m;
        var matrix3d = 'matrix3d(' +
            this.epsilon( elements[ 0 ] ) + ',' +
            this.epsilon( elements[ 1 ] ) + ',' +
            this.epsilon( elements[ 2 ] ) + ',' +
            this.epsilon( elements[ 3 ] ) + ',' +
            this.epsilon( - elements[ 4 ] ) + ',' +
            this.epsilon( - elements[ 5 ] ) + ',' +
            this.epsilon( - elements[ 6 ] ) + ',' +
            this.epsilon( - elements[ 7 ] ) + ',' +
            this.epsilon( elements[ 8 ] ) + ',' +
            this.epsilon( elements[ 9 ] ) + ',' +
            this.epsilon( elements[ 10 ] ) + ',' +
            this.epsilon( elements[ 11 ] ) + ',' +
            this.epsilon( elements[ 12 ] ) + ',' +
            this.epsilon( elements[ 13 ] ) + ',' +
            this.epsilon( elements[ 14 ] ) + ',' +
            this.epsilon( elements[ 15 ] ) +
            ')'

        return 'translate(-50%,-50%)' + matrix3d
    }

    renderObject(object, scene, camera, cameraCSSMatrix ) {
        if (object instanceof CSS3DObject) {
            var style
            // object.scaling = new BABYLON.Vector3(0.89, 0.6666, 1);
            // object.scaling = object.scaling.multiplyByFloats(0.2, 0.1, 1);
            var objectMatrixWorld = object.getWorldMatrix().clone()
            var camMatrix = camera.getWorldMatrix()
            var innerMatrix = objectMatrixWorld.m.slice()

            // Set scaling
            const youtubeVideoWidth = 4.8
            const youtubeVideoHeight = 3.6

            innerMatrix[0] *= 0.01 / youtubeVideoWidth
            innerMatrix[2] *= 0.01 / youtubeVideoWidth
            innerMatrix[5] *= 0.01 / youtubeVideoHeight

            // Set position from camera
            innerMatrix[12] = -camMatrix.m[12] + object.position.x
            innerMatrix[13] = -camMatrix.m[13] + object.position.y
            innerMatrix[14] = camMatrix.m[14] - object.position.z
            innerMatrix[15] = camMatrix.m[15] * 0.00001

            objectMatrixWorld = BABYLON.Matrix.FromArray(innerMatrix)
            objectMatrixWorld = objectMatrixWorld.scale(100)
            style = this.getObjectCSSMatrix( objectMatrixWorld, cameraCSSMatrix)
            var element = object.element
            var cachedObject = this.cache.objects.get( object )

            if ( cachedObject === undefined || cachedObject.style !== style ) {

                element.style.webkitTransform = style
                element.style.transform = style

                var objectData = { style: style }

                this.cache.objects.set( object, objectData )
            }
            if ( element.parentNode !== this.cameraElement ) {
                this.cameraElement.appendChild( element )
            }

        } else if ( object instanceof BABYLON.Scene ) {
            for ( var i = 0, l = object.meshes.length; i < l; i ++ ) {
                this.renderObject( object.meshes[ i ], scene, camera, cameraCSSMatrix )
            }
        }
    }

    render(scene, camera) {
        var projectionMatrix = camera.getProjectionMatrix()
        var fov = projectionMatrix.m[5] * this.heightHalf

        if (this.cache.camera.fov !== fov) {

            if (camera.mode == BABYLON.Camera.PERSPECTIVE_CAMERA ) {
                this.domElement.style.webkitPerspective = fov + 'px'
                this.domElement.style.perspective = fov + 'px'
            } else {
                this.domElement.style.webkitPerspective = ''
                this.domElement.style.perspective = ''
            }
            this.cache.camera.fov = fov
        }

        if ( camera.parent === null ) camera.computeWorldMatrix()

        var matrixWorld = camera.getWorldMatrix().clone()

        let x = 0.24, y = 0.32, z = 1;
        x = 1;
        y = 1;
        z = 1;
        // const scaleMatrix = BABYLON.Matrix.Scaling(x, y, z);
        // matrixWorld = scaleMatrix.multiply(matrixWorld);



        var rotation = matrixWorld.clone().getRotationMatrix().transpose()
        var innerMatrix = matrixWorld.m

        innerMatrix[1] = rotation.m[1]
        innerMatrix[2] = -rotation.m[2]
        innerMatrix[4] = -rotation.m[4]
        innerMatrix[6] = -rotation.m[6]
        innerMatrix[8] = -rotation.m[8]
        innerMatrix[9] = -rotation.m[9]

        matrixWorld = BABYLON.Matrix.FromArray(innerMatrix)

        var cameraCSSMatrix = 'translateZ(' + fov + 'px)' + this.getCameraCSSMatrix( matrixWorld )

        var style = cameraCSSMatrix + 'translate(' + this.widthHalf + 'px,' + this.heightHalf + 'px)'

        if (this.cache.camera.style !== style) {
            this.cameraElement.style.webkitTransform = style
            this.cameraElement.style.transform = style
            this.cache.camera.style = style
        }

        this.renderObject(scene, scene, camera, cameraCSSMatrix )
    }
}
