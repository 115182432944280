export class FileUpload {
    files = [];
    dropArea: HTMLElement;
    hasImagePreview = false;
    onFileSelected: CallableFunction = (files: FileList) => {};

    constructor(dropArea: HTMLElement) {
        this.dropArea = dropArea;
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, preventDefaults, false)
        })

        function preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        }

        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, highlight, false)
        });

        ['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, unhighlight, false)
        });

        function highlight(e) {
            dropArea.classList.add('highlight')
        }

        function unhighlight(e) {
            dropArea.classList.remove('highlight')
        }


        dropArea.addEventListener('drop', (e) => {this.handleDrop(e)}, false);

        (dropArea.querySelector('.fileElem') as HTMLInputElement)
            .addEventListener('change', (e) => {this.handleFiles((e.target as HTMLInputElement).files)}, false);
    }

     handleDrop(e: DragEvent) {
        let dt = e.dataTransfer;
        let files = dt.files;

        this.handleFiles(files);
    }

     handleFiles(files) {
        if(files.length) {
            const file = files[0];
            (this.dropArea.querySelector('.fileElem') as HTMLInputElement).files = files;
            // files.forEach(this.uploadFile);
            this.hasImagePreview && this.previewFile(file);
            this.dropArea.querySelector('.file-name').innerHTML = file.name;
            this.dropArea.querySelector('.file-name').classList.remove('hidden');
        }
         this.onFileSelected(files);
    }

    previewFile(file) {
        let img: HTMLImageElement = this.dropArea.parentElement.parentElement.querySelector('.gallery img')
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            img.src = reader.result as string;
        }
        reader.onerror = () => {
            img.src = null;
        }
    }
}
