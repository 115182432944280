import {btnProgressOff, btnProgressOn, getById, hide, showMessage} from "./helpers";
import {bindDownloadModel, firebase, functions} from "./index";
import * as Cookies from "js-cookie";
import {MapstarUI} from "./mapstar-ui";

export class MapstarAuth {
    mapstarUI: MapstarUI;

    constructor(mapstarUI) {
        this.mapstarUI = mapstarUI;
    }

    authForms() {
        document.getElementById('sign-in').onclick = this.toggleLoginForm;
        document.querySelectorAll('#login-form .sign-up-btn, #sign-up-form .login-btn')
            .forEach((el: HTMLElement) => el.onclick = () => this.switchForms())

        getById('sign-up-form').onsubmit = (ev) => {
            const elements = (ev.target as HTMLFormElement).elements;
            ev.preventDefault();
            // @ts-ignore
            const username = elements.username.value;
            // @ts-ignore
            const email = elements.email.value;
            // @ts-ignore
            const password = elements.password.value;
            // @ts-ignore
            const confirm = elements.confirm.value;
            if(password !== confirm) {
                showMessage('#sign-up-form .info', 'Password and confirm password don\'t match')
                return;
            }
            const signUpBtn = document.querySelector('#sign-up-form .sign-up-btn');
            btnProgressOn(signUpBtn);
            functions.httpsCallable('registerTrigger')({ 'email': email, 'displayName': username })
                .then(resp => {
                    if(resp.data.status === 'USER_NOT_EXISTS'){
                        firebase.auth().createUserWithEmailAndPassword(email, password).then(data => {
                            showMessage('#sign-up-form .info', 'Account successfully created');
                            functions.httpsCallable('UpdateProfile')({'displayName': username}).then(resp => {
                            }).catch(err => console.error(err))
                            this.signIn(email, password);
                        }).catch(function (error) {
                            showMessage('#sign-up-form .info', error.message)
                        }).finally(() => btnProgressOff(signUpBtn));
                    } else if (resp.data.status === 'USER_EXISTS_FIREBASE_ACCOUNT_AVAILABLE') {
                        showMessage('#sign-up-form .info', 'User with this email is already registered')
                        btnProgressOff(signUpBtn)
                    } else {
                        showMessage('#sign-up-form .info', resp.data.error ?? 'Oops')
                        btnProgressOff(signUpBtn)
                    }
                }).catch(err => { btnProgressOff(signUpBtn); console.error(err) })
        }
        getById('login-form').onsubmit = (ev) => {
            ev.preventDefault();
            // @ts-ignore
            const username = ev.target.elements.username.value;
            // @ts-ignore
            const password = ev.target.elements.password.value;
            this.signIn(username, password);
        }

        document.querySelectorAll('.form .close-btn').forEach((el: HTMLElement) => el.onclick = this.hideForms)
    }

    signOut() {
        Cookies.remove('firebaseIdToken');
        Cookies.remove('firebaseUserId');
        this.mapstarUI.watched.isAuthed = false;
        // @ts-ignore
        this.mapstarUI.isEditMode && this.mapstarUI.exitEditMode();
        this.mapstarUI.closeModals();
        hide(getById('edit-model-btn'));
        getById('my-layers-list').innerHTML = '';
        this.mapstarUI.showCreatorPanel();
    }
    signIn(username, password) {
        firebase.auth().signInWithEmailAndPassword(username, password).then(data => {
            // @ts-ignore
            const userId = data.user.$.W;
            if ((getById('remember') as HTMLInputElement).checked) {
                // @ts-ignore
                Cookies.set('firebaseIdToken', data.user.xa, {SameSite: 'Lax'});
                Cookies.set('firebaseUserId', userId, {SameSite: 'Lax'});
            }
            this.mapstarUI.userId = userId;
            this.mapstarUI.watched.isAuthed = true;

            this.hideForms();
            bindDownloadModel();
            this.mapstarUI.getMyLayers();
            this.mapstarUI.showCreatorPanel();
        }).catch(function (error) {
            showMessage('#login-form .info', error.message);
        });
    }

    toggleLoginForm() {
        getById('login-form').classList.toggle('invisible')
    }
    hideForms() {
        document.querySelectorAll('.form').forEach((el: HTMLElement) => el.parentElement.classList.add('invisible'))
    }
    switchForms() {
        document.querySelectorAll('.form').forEach((el: HTMLElement) => el.parentElement.classList.toggle('invisible'))
    }
}
