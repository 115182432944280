import 'babylonjs-serializers';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import {ItemsLibrary} from "./items-library";
import {Env} from "./environment"
import {config} from "./config"
import * as ProgressBar from 'progressbar.js'
import * as Cookies from "js-cookie";
import {GLTFFileLoader} from 'babylonjs-loaders';
import {MapstarUI} from "./mapstar-ui";
import {FullReconstructionItem, ReadReconstruction} from './type/readReconstruction';
import {Layer} from "./type/layer";
import {getById, hide, show} from "./helpers";

export {
    isAuthed,
    firebase,
    functions,
    layer,
    bindDownloadModel,
    itemsLibrary as itemsLibraryInst,
    scene,
    engine,
    saveItems,
    data,
    createPreview,
    reloadReconstruction
};
var assetUrl, cameraPosition, engine, scene, layer: Layer, data: ReadReconstruction,
    items: { [p: string]: FullReconstructionItem }, itemsLibrary: ItemsLibrary, UI: MapstarUI, glbRoot;

// html tags
var root = document.getElementById('root');
var canvas = document.getElementById("renderCanvas");
var uiLayer = document.getElementById("ui-layer");
var preloader = document.getElementById('preloader');
var progress = document.getElementById('progress');
var logo = document.getElementById('logo');

const env = new Env();
var currentPluginName;
var skyboxPath = env.skyboxes[env.defaultSkyboxIndex];
var debugLayerEnabled = false;
// var debugLayerEnabled = true;


if (!BABYLON.Engine.isSupported()) {
    preloader.innerText = 'Your browser doesn\'t support Babylon.js engine';
    throw new Error('BABYLON NOT SUPPORTED');
}

//firebase
// Initialize Firebase
var idToken = Cookies.get('firebaseIdToken');
var isAuthed = (typeof idToken != 'undefined');
firebase.initializeApp(config);
var functions = firebase.app().functions('europe-west1');

window.addEventListener('load', (event) => {
    root.style.display = 'none'
    preloader.style.display = 'none'
    progress.style.display = 'block'
});

canvas.addEventListener("contextmenu", function (evt) {
    evt.preventDefault();
}, false);

BABYLON.Engine.ShadersRepository = "/src/Shaders/";
// This is really important to tell Babylon.js to use decomposeLerp and matrix interpolation
BABYLON.Animation.AllowMatricesInterpolation = true;

// Setting up some GLTF values
GLTFFileLoader.IncrementalLoading = false;
BABYLON.SceneLoader.OnPluginActivatedObservable.add(function (plugin) {
    currentPluginName = plugin.name;
    if (currentPluginName === "gltf") {
        (plugin as unknown as GLTFFileLoader).onValidatedObservable.add(function (results) {
            if (results.issues.numErrors > 0) {
                debugLayerEnabled = true;
            }
        });
    }
});

engine = new BABYLON.Engine(canvas as HTMLCanvasElement, true, {
    premultipliedAlpha: false,
    preserveDrawingBuffer: true
});
engine.loadingUIBackgroundColor = "#2A2342";

// Resize
window.addEventListener("resize", function () {
    engine.resize();
});

void async function () {
    //get the imported document in templates:
    var templates = document.createElement('template');
    templates.innerHTML = await (await fetch('src/ui.html')).text();

    UI = new MapstarUI(templates);
}()

checkUrl();

function sceneLoaded(babylonScene, babylonEngine) { //console.log("SCENE LOADED");
    babylonEngine.clearInternalTexturesCache();

    babylonScene.skipFrustumClipping = true;
    let currentGroup = null;
    const playFirstAnimationGroup = true;
    if (babylonScene.animationGroups.length > 0) {
        let currentGroupIndex = playFirstAnimationGroup ? 0 : babylonScene.animationGroups.length - 1;
        currentGroup = babylonScene.animationGroups[currentGroupIndex];
        currentGroup.play(true);
    }

    setUpCamera(babylonScene);
    setLighting(babylonScene);
}

function loadFromAssetUrl() {
    delayCreateScene(assetUrl);
}

function delayCreateScene(assetUrl) { //console.log("DELAY CREATE SCENE");
    var glbRoot;
    const bar = createProgressBar();
    // https://www.babylonjs-playground.com/#J21C6T#1
    BABYLON.SceneLoader.Load("file:", assetUrl, engine, createdScene => {
            //console.log("IMPORT MESH: CREATE DEFAULT CAMERA AND LIGHT");
            scene = createdScene;
            scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
            scene.createDefaultCameraOrLight(true, true, true);
            glbRoot = scene.meshes.filter(m => m.name !== '__root__')[0].parent;
            glbRoot.name = "__reconstructionRoot__";
            glbRoot.id = "__reconstructionRoot__";
            glbRoot.rotationQuaternion = undefined;

            sceneLoaded(scene, engine);

            setCameraOnReconCenter(glbRoot, scene.activeCamera);
            setCameraBehavior(scene.activeCamera);
            setBirdEye(scene.activeCamera);

            createItems();

            scene.whenReadyAsync().then(function () {
                root.style.display = 'block'
                progress.style.display = 'none'
                logo.style.display = 'none'

                debugLayerEnabled && turnOnDebug();
                !debugLayerEnabled && uiLayer.classList.remove('hidden');
                engine.runRenderLoop(function () {
                    scene.render();
                });
                UI.handleHelp();
                layer && functions.httpsCallable('IncreaseLayerViewsCounter')({'layerId': layer._id});
                hide(getById('spinnerReload'));
            });
        },
        (event) => {
            bar.animate((event.loaded / event.total), {duration: 0.0001});
        });
}

function createItems() {
    if (items && typeof items === 'object') {
        itemsLibrary = new ItemsLibrary(scene, glbRoot, engine);
        for (let key in items) {
            let item = items[key];
            switch (item.contentType) {
                case "text":
                    item = itemsLibrary.initialItemPrepare(item);
                    itemsLibrary.createTextObject(item);
                    break;
                case "image":
                    item = itemsLibrary.initialItemPrepare(item);
                    itemsLibrary.createImageObject(item);
                    break;
                case "url":
                    item = itemsLibrary.initialItemPrepare(item);
                    itemsLibrary.createLinkObject(item);
                    break;
                case "audio":
                    item = itemsLibrary.initialItemPrepare(item);
                    itemsLibrary.createAudioObject(item);
                    break;
                case "video":
                    if (item.content.videoType === 'youtube') {
                        item.rotation.x = item.rotation.z = 0; //force reset of unwanted axes to get rid of canvas rotation for sure
                        item = itemsLibrary.initialItemPrepare(item);
                        itemsLibrary.createYoutubeObject(item);
                    } else {
                        console.info('UNKNOWN VIDEO TYPE');
                    }
                    break;
                case "model":
                    item = itemsLibrary.initialItemPrepare(item);
                    itemsLibrary.createCommonModel(item);
                    break;
                case "custom":
                    item = itemsLibrary.initialItemPrepare(item);
                    itemsLibrary.createUserModel(item);
                    break;
                default:
                    console.info('UNKNOWN ITEM TYPE');
            }
        }
    }

}

function setLighting(currentScene) {
    if (currentPluginName === "gltf") {
        if (!currentScene.environmentTexture) {
            currentScene.environmentTexture = env.loadSkyboxPathTexture(skyboxPath, currentScene);
        }
        currentScene.createDefaultSkybox(currentScene.environmentTexture, true, (currentScene.activeCamera.maxZ - currentScene.activeCamera.minZ) / 2, 0.3, false);
    } else {
        let pbrPresent = false;
        for (var i = 0; i < currentScene.materials.length; i++) {
            if (currentScene.materials[i]._transparencyMode !== undefined) {
                pbrPresent = true;
                break;
            }
        }

        if (pbrPresent) {
            if (!currentScene.environmentTexture) {
                currentScene.environmentTexture = env.loadSkyboxPathTexture(skyboxPath, currentScene);
            }
        } else {
            currentScene.createDefaultLight();
        }
    }
}

function setCameraOnReconCenter(rootMesh, camera) {
    const vectors = rootMesh.getHierarchyBoundingVectors();
    const reconCenter = BABYLON.Vector3.Center(vectors.min, vectors.max);
    // const pov = BABYLON.MeshBuilder.CreateSphere('pov', {diameter: 0.3});
    // pov.position.copyFrom(reconCenter);
    camera.setTarget(reconCenter);
}

function setBirdEye(camera) {
    //3rd person view, bird-eye
    camera.beta = 1.16;
    camera.radius /= 1.5;
}

function setCameraBehavior(camera) {
    // set zoom settings: https://playground.babylonjs.com/#7CHX3V#2
    camera.wheelDeltaPercentage = 0.0025;
    camera.useFramingBehavior = true;

    // camera.useAutoRotationBehavior = true;

    camera.pinchDeltaPercentage = 0.0025;
    camera.pinchToPanMaxDistance = 24;
    camera.panningSensibility = 1000;
}

function setUpCamera(scene) {
    // Attach camera to canvas inputs
    if (!scene.activeCamera || scene.lights.length === 0) {
        //console.log("SETUP CAMERA: CREATE NEW DEFAULT CAMERA");
        scene.createDefaultCamera(true);

        if (cameraPosition) {
            scene.activeCamera.setPosition(cameraPosition);
        } else {
            if (currentPluginName === "gltf") {
                // glTF assets use a +Z forward convention while the default camera faces +Z. Rotate the camera to look at the front of the asset.
                scene.activeCamera.alpha += Math.PI;
            }

            // Enable camera's behaviors
            scene.activeCamera.useFramingBehavior = true;

            var framingBehavior = scene.activeCamera.getBehaviorByName("Framing");
            framingBehavior.framingTime = 0;
            framingBehavior.elevationReturnTime = -1;

            if (scene.meshes.length) {
                scene.activeCamera.lowerRadiusLimit = null;

                var worldExtends = scene.getWorldExtends(function (mesh) {
                    return mesh.isVisible && mesh.isEnabled();
                });
                framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
            }
        }
    }

    // scene.activeCamera.pinchPrecision = 200 / scene.activeCamera.radius;
    scene.activeCamera.upperRadiusLimit = 5 * scene.activeCamera.radius;

    // set zoom settings: https://playground.babylonjs.com/#7CHX3V#2
    scene.activeCamera.wheelDeltaPercentage = 0.000001;
    scene.activeCamera.pinchDeltaPercentage = 0.000001;

    scene.activeCamera.attachControl(canvas);
}

function createProgressBar() {
    return new ProgressBar.Circle('#progress', {
        strokeWidth: 6,
        color: '#18F7FE',
        trailColor: '#555',
        trailWidth: 1,
        step: (state, bar) => {
            bar.setText(Math.round(bar.value() * 100) + ' %');
        },
        text: {
            style: {
                color: '#18F7FE',
                position: 'absolute',
                right: '0',
                top: '40px',
                'text-align': 'center',
                width: '100%',
                'text-indent': '10px'
            },
            autoStyleContainer: false
        },
    });
}

function checkUrl() {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    // id is missing, show default reconstruction
    const fileId = id ?? "66aa6f6c-427a-440d-b319-d3c7be1244f9"
    getFileUrl(fileId);
}

function bindDownloadModel() {
    document.getElementById('downloadModel').onclick = (e) => {
        downloadFile()
    }
}

function getFileUrl(fileId) {
    const readReconstruction = functions.httpsCallable('ReadReconstruction');

    readReconstruction({reconstructionId: fileId}).then(function (result) {
        data = result.data;
        const fileLink = result.data.reconstruction.file;
        const layerData = result.data.layer[0]; // "layer" field is an array of layers
        if (fileLink) {
            assetUrl = fileLink;
            layer = layerData;
            UI.populateUI(result.data);
            if (isAuthed) {
                bindDownloadModel();
            }

            items = JSON.parse(JSON.stringify(layer.items)); //copy by value
            loadFromAssetUrl();
        } else {
            console.warn("No file link or layer data!");
        }
    }).catch(function (error) {
        console.warn("Error getting document:", error);
    })
}

function reloadReconstruction(fileId) {
    show(getById('spinnerReload'));
    itemsLibrary.clearVideos();
    history.pushState({id: fileId}, 'Mapstar 3D Viewer', '?id=' + fileId);
    getFileUrl(fileId);
}

window.onpopstate = event => {
    const id = event.state?.id;
    id && reloadReconstruction(id);
}

function showAxis(size) {
    var makeTextPlane = function (text, color, size) {
        const dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene, true);
        dynamicTexture.hasAlpha = true;
        dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color, "transparent", true);
        const plane = BABYLON.Mesh.CreatePlane("TextPlane", size, scene, true);
        const material: BABYLON.StandardMaterial = new BABYLON.StandardMaterial("TextPlaneMaterial", scene);
        material.specularColor = new BABYLON.Color3(0, 0, 0);
        material.diffuseTexture = dynamicTexture;
        material.backFaceCulling = false;
        plane.material = material;
        return plane;
    };

    const axisX = BABYLON.Mesh.CreateLines("axisX", [
        BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
        new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
    ], scene);
    axisX.color = new BABYLON.Color3(1, 0, 0);
    const xChar = makeTextPlane("X", "red", size / 10);
    xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);
    const axisY = BABYLON.Mesh.CreateLines("axisY", [
        BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(-0.05 * size, size * 0.95, 0),
        new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(0.05 * size, size * 0.95, 0)
    ], scene);
    axisY.color = new BABYLON.Color3(0, 1, 0);
    const yChar = makeTextPlane("Y", "green", size / 10);
    yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);
    const axisZ = BABYLON.Mesh.CreateLines("axisZ", [
        BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
        new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, 0.05 * size, size * 0.95)
    ], scene);
    axisZ.color = new BABYLON.Color3(0, 0, 1);
    const zChar = makeTextPlane("Z", "blue", size / 10);
    zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);
}

function downloadFile() {
    const link = document.createElement("a");
    link.href = assetUrl;
    link.target = "_blank";
    link.click();
}


function saveItems() {
    show(getById('spinner'));
    const items = itemsLibrary.prepareForSaving();
    const newLayer = {...data.layer[0], ...{layerId: data.layer[0]._id}};
    // @ts-ignore
    newLayer.items = items;

    functions.httpsCallable('UpdateLayer')(newLayer)
        .then(res => {
            if (res?.data?.error !== 'NONE') {
                // @ts-ignore
                console.error(res?.data.error);
                UI.addErrorAlert('Error on saving: ' + res?.data.error);
            } else {
                UI.addSuccessAlert('Saving done');
                UI.exitEditMode();
            }
        }).catch(err => {
        console.error(err);
        UI.addErrorAlert('Error on saving: ' + err);
    }).finally(() => hide(getById('spinner')))
}

function deleteLayer(id) {
    functions.httpsCallable('DeleteLayer')({id}).then(res => {
        console.log(res);
    }).catch(err => {
        console.error(err);
    })
}

getById('turnOnDebug').onclick = () => turnOnDebug();

function turnOnDebug() {
    scene.debugLayer.show();
    showAxis(1);
}

function createPreview(file, imageSelector) {
    const newCanvas: HTMLCanvasElement = getById('newCanvas') as HTMLCanvasElement;
    const newEngine = new BABYLON.Engine(newCanvas, true, {preserveDrawingBuffer: true, stencil: true});
    newEngine.loadingUIBackgroundColor = "#2A2342";
    const width = 800;
    const height = 800;
    const url = URL.createObjectURL(file);
    document.querySelector(imageSelector).src = 'Assets/img/loader.gif';
    BABYLON.SceneLoader.Load("", url, newEngine, newScene => {
        // newScene.clearColor = new BABYLON.Color4(0,0,0,0);
        newScene.createDefaultCameraOrLight(true, true, true);
        const glbRoot: BABYLON.Mesh = newScene.meshes.filter(m => m.name !== '__root__')[0].parent as BABYLON.Mesh;
        glbRoot.rotationQuaternion = undefined;

        newEngine.clearInternalTexturesCache();
        newScene.skipFrustumClipping = true;
        // setLighting(babylonScene);
        setUpCamera(newScene);
        setCameraOnReconCenter(glbRoot, newScene.activeCamera);
        setCameraBehavior(newScene.activeCamera);
        setBirdEye(newScene.activeCamera);

        newScene.whenReadyAsync().then(function () {
            BABYLON.Tools.CreateScreenshotUsingRenderTarget(newEngine, newScene.activeCamera, {
                width,
                height
            }, base64 => {
                const image = new Image();
                image.src = base64;
                image.onload = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0);
                    ctx.translate(width, 0);
                    ctx.scale(-1, 1);
                    const flippedImage = document.querySelector(imageSelector);
                    (!flippedImage) && console.warn('No image found by selector');
                    flippedImage.src = canvas.toDataURL('image/png');
                };
            });
        });
    }, null, () => {
        document.querySelector(imageSelector).removeAttribute('src');
        console.warn('Can\'t create preview');
    }, ".glb");
}
