import {config} from "./config";
import * as Cookies from "js-cookie";
import 'firebase/functions';
import {data, engine, firebase, functions, reloadReconstruction, isAuthed, itemsLibraryInst, layer, saveItems} from "./index";
import {FileUpload} from "./file-upload";
import {FeedReconstruction, ReconstructionItem} from "./type/createReconstruction";
import {YouTubeURLParser} from "@iktakahiro/youtube-url-parser"
import {AudioItem, ImageItem, LinkItem, ModelItem, TextItem, UserItem, VideoItem} from "./type/newItem";
import {CSV} from "./csv";
import {v4 as uuidv4} from 'uuid';
import {UpdateLayer} from "./type/layer";
import KeenSlider from 'keen-slider'
import {ReadProfile} from "./type/readProfile";
import {
    deepEqual,
    filterObjKeys,
    getById,
    hide,
    show,
    showMessage, sortObject,
    toggle
} from "./helpers";
import {MapstarAuth} from "./mapstar-auth";
import {MapstarUpload} from "./mapstar-upload";
import {ReactiveAbstract} from "./reactive.abstract";
import {ReadMyItems} from "./type/ReadMyItems";
import {itemCategories} from "./item-categories";
import {MyItem} from "./type/myItem";
import md5 from 'md5-hash';
import {ItemsLibrary} from "./items-library";

export class MapstarUI extends ReactiveAbstract {
    uiTemplates: HTMLTemplateElement;
    editItemTemplates: HTMLTemplateElement;
    isEditMode: boolean;
    isEditLayerInfo = false;
    isAuthed: boolean;
    isMyModelsReady: boolean;
    userId = Cookies.get('firebaseUserId');
    profile: ReadProfile;
    myItems: MyItem[] = [];
    config;
    watched: MapstarUI; // do set/get properties to be watched with "this.watched" instead of "this"
    mapstarAuth: MapstarAuth;
    mapstarUpload: MapstarUpload;

    constructor(uiTemplates) {
        super();
        this.uiTemplates = uiTemplates;
        this.addUiTemplate('ui-layer-tpl');
        this.config = config;
        this.bindPropertyChanges();

        this.mapstarAuth = new MapstarAuth(this);
        this.mapstarUpload = new MapstarUpload(this);
        this.mapstarAuth.authForms();
    }

    bindPropertyChanges() {
        this.setHandlers['isEditMode'] = () => {
            this.isEditMode ? this.isOwner() && show(document.querySelector('#location .edit-btn'))
                : hide(document.querySelector('#location .edit-btn'))
        }
        this.setHandlers['isAuthed'] = () => {
            if(this.isAuthed){
                this.switchDownload();
                this.readProfile();
                show(getById('profileAvatar'));
                this.showEditBtn();
                hide(getById('sign-in'));
                show(getById('profile-menu'));
                show(getById('profileAvatar'));
            } else {
                show(getById('sign-in'));
                hide(getById('profile-menu'));
                hide(getById('profileAvatar'));
            }
        }
        this.setHandlers['isMyModelsReady'] = () => {
            document.querySelector('#my-layers .body').classList[this.isMyModelsReady ? 'remove' : 'add']('disabled');
        }
    }

    populateUI(data) {
        var self = this;
        this.watched.isAuthed = isAuthed;
        this.watched.isEditMode = false;
        ['views', 'likesCounter', 'commentsCounter'].forEach(name => {
            const el = getById(name);
            if (el && typeof layer[name] !== "undefined") {
                el.querySelector('div').innerText = layer[name];
            }
        });
        this.readMyItems();

        handleLayerInfo();
        this.showCreatorPanel();
        this.initEditLayer();

        const coords = data.anchor.data.coordinates;
        handleCoordinates.call(this);

        function handleLayerInfo() {
            document.querySelector('#location .date .text').innerHTML = new Date(data.reconstruction.lastUpdated)
                .toLocaleDateString('de-DE', {hour: '2-digit', hour12: false, minute: '2-digit'})
            document.querySelector('#location .title .text').innerHTML = layer.title;
            var layerDescriptionSelector = document.querySelector('#location .description .text');
            layerDescriptionSelector.innerHTML = (layer.description === null || layer.description.length === 0 || !layer.description.trim() ? "&nbsp;" : layer.description );

            (document.querySelector('#location .edit-btn') as HTMLElement).onclick = () => {
                self.isEditLayerInfo ? exitEditState() : enterEditState();
            }
            (document.querySelector('#location .cancel') as HTMLElement).onclick = () => exitEditState();
            (document.querySelector('#location .save') as HTMLElement).onclick = () => {
                const updateLayer = layer as unknown as UpdateLayer;
                updateLayer.layerId = layer._id;
                updateLayer.items = Object.values(layer.items);
                updateLayer.title = layer.title = (getById('location-title') as HTMLInputElement).value;
                updateLayer.description = layer.description = (getById('location-description') as HTMLTextAreaElement).value;
                functions.httpsCallable('UpdateLayer')(layer).then(resp => {
                    exitEditState();
                }).catch(err => console.error(err))

            }

            function exitEditState() {
                self.isEditLayerInfo = false;
                getById('location').classList.remove('active');
                document.querySelector('#location .title .text').innerHTML = layer.title;
                document.querySelector('#location .description .text').innerHTML = layer.description;
            }

            function enterEditState() {
                self.isEditLayerInfo = true;
                getById('location').classList.add('active');
                (getById('location-title') as HTMLInputElement).value = layer.title;
                (getById('location-description') as HTMLTextAreaElement).value = layer.description;
            }
        }

        function handleCoordinates() {
            self.makeReverseGeocoding(coords, (text) => {
                const loc: HTMLElement = document.querySelector('.location > div');
                loc.innerHTML = text;
                loc.title = text;
            });

            (document.querySelector('#location #pin a') as HTMLLinkElement).href = 'https://maps.google.com/?ll=' + coords.lat + ',' + coords.long;
        }
    }

    readMyItems() {
        functions.httpsCallable('ReadMyItems')({}).then(resp => {
            const readMyItems: ReadMyItems = resp.data;
            this.myItems = readMyItems.items;
            if (readMyItems.itemsCount) {
                show(getById('myItemsMenu'));
                this.handleMyItemsModals();
            }
        })
    }

    private switchDownload() {
        getById('downloadModel').classList.toggle('hidden')
    }

    private readProfile() {
        functions.httpsCallable('ReadProfile')().then((resp) => {
            this.profile = resp.data as unknown as ReadProfile;
            const profileAvatarImgSelector = document.querySelector('#profileAvatar img') as HTMLImageElement;
            const profileAvatarTooltipSelector = document.querySelector('#profileAvatar span');
            profileAvatarImgSelector.src = this.profile?.profilePicture || 'Assets/img/Avatar_anonymous.png';
            profileAvatarImgSelector.alt = this.profile?.displayName || '';
            profileAvatarTooltipSelector.innerHTML = this.profile?.displayName || '';
        }).catch(err => console.error('ReadProfile', err))
    }

    private showEditBtn() {
        if(this.isOwner()) {
            show(getById('edit-model-btn'));
            show(getById('delete-model-btn'));
        }
    }

    handleHelp() {
        if(!Cookies.get('visited')) {
            setTimeout(() => {
                getById('navigation').classList.add('active');
                setTimeout(() => {
                    getById('navigation').classList.remove('active');
                    show(getById('help'))

                }, 3000)
            }, 1000);
            Cookies.set('visited', 'true', {SameSite: 'Lax'});
        } else {
            show(getById('help'));
        }

        getById('help').onclick = () => {
            getById('navigation').classList.add('active');
            hide(getById('help'));
        }
        getById('navigation').onclick = () => {
            getById('navigation').classList.remove('active');
            show(getById('help'));
        }
    }

    bindEditModeModals() {
        const self = this;

        if(!getById('add-text-modal-menu-tpl')) {
            this.addEditItemsTemplate('add-text-modal-menu-tpl');
            getById('textMenu').onclick = () => {
                const newItem = new TextItem();
                itemsLibraryInst.createTextObject(newItem, true);
                itemsLibraryInst.onItemEdit(newItem);
            }
        }
        if(!getById('text-modal-menu-tpl')) {
            this.addEditItemsTemplate('text-modal-menu-tpl');
        }
        if(!getById('add-link-modal-menu-tpl')) {
            this.addEditItemsTemplate('add-link-modal-menu-tpl');
            getById('linkMenu').onclick = () => {
                const newItem = new LinkItem();
                itemsLibraryInst.createLinkObject(newItem, true);
                itemsLibraryInst.onItemEdit(newItem);
            }
        }
        if(!getById('link-modal-menu-tpl')) {
            this.addEditItemsTemplate('link-modal-menu-tpl');
        }

        if(!getById('video-modal-menu-tpl')) {
            this.addEditItemsTemplate('video-modal-menu-tpl');
        }
        if(!getById('image-modal-menu-tpl')) {
            this.addEditItemsTemplate('image-modal-menu-tpl');
        }
        if(!getById('audio-modal-menu-tpl')) {
            this.addEditItemsTemplate('audio-modal-menu-tpl');
        }

        if(!getById('add-media-modal-menu-tpl')) {
            this.addEditItemsTemplate('add-media-modal-menu-tpl');
            this.bindModalClose(getById('add-media-modal-menu'));
            getById('mediaMenu').onclick = () => {
                this.openModal(getById('add-media-modal-menu'));
                document.querySelectorAll('.submm .back-arrow').forEach((el: HTMLElement) => el.onclick = () => {
                    this.closeModals();
                    this.openModal(getById('add-media-modal-menu'));
                })
                getById('show-image-modal').onclick = () => {
                    hide(getById('add-media-modal-menu'));
                    const newItem = new ImageItem();
                    itemsLibraryInst.createImageObject(newItem, true);
                    itemsLibraryInst.onItemEdit(newItem);
                }
                getById('show-audio-modal').onclick = () => {
                    hide(getById('add-media-modal-menu'));
                    const newItem = new AudioItem();
                    itemsLibraryInst.createAudioObject(newItem, true);
                    itemsLibraryInst.onItemEdit(newItem);
                }
                getById('show-youtube-modal').onclick = () => {
                    hide(getById('add-media-modal-menu'));
                    const newItem = new VideoItem();
                    itemsLibraryInst.createYoutubeObject(newItem, true);
                    itemsLibraryInst.onItemEdit(newItem);
                }
            }
        }

        if(!getById('model-categories-tpl')) {
            this.addEditItemsTemplate('model-categories-tpl');
            getById('modelMenu').onclick = () => {
                let modal = getById('model-categories');
                this.openModal(modal);
                this.bindModalClose(modal);
                document.querySelectorAll('#model-categories .model-category').forEach(
                    (el: HTMLElement) => el.onclick = async (e) => {
                        await showCategoryModal.call(this, modal, (e.currentTarget as HTMLElement).dataset.category);
                    })
            }
        }
        this.handleMyItemsModals();

        itemsLibraryInst.onItemEdit = (item: ReconstructionItem) => {
            let modal;
            switch (item.contentType) {
                case 'text':
                    modal = getById('text-modal-menu');
                    this.openModal(modal);
                    (modal.querySelector('#textarea') as HTMLTextAreaElement).value = item.content.text;
                    (modal.querySelector('.save') as HTMLButtonElement).onclick = (e) => {
                        itemsLibraryInst.updateTextItem((modal.querySelector('#textarea') as HTMLTextAreaElement).value, item._id);
                        this.closeModal(modal);
                    }
                    this.bindModalClose(modal);
                    break;

                case 'url':
                    modal = getById('link-modal-menu');
                    this.openModal(modal);
                    (modal.querySelector('#link') as HTMLTextAreaElement).value = item.content.url;
                    (modal.querySelector('#title') as HTMLTextAreaElement).value = item.content.title;
                    (modal.querySelector('.save') as HTMLButtonElement).onclick = (e) => {
                        itemsLibraryInst.updateLinkItem(
                            (modal.querySelector('#link') as HTMLInputElement).value, (modal.querySelector('#title') as HTMLTextAreaElement).value, item._id);
                        this.closeModal(modal);
                    }
                    this.bindModalClose(modal);
                    break;

                case 'video':
                    modal = getById('video-modal-menu');
                    this.openModal(modal);
                    (modal.querySelector('#url') as HTMLTextAreaElement).value = 'https://youtu.be/'+item.content.videoId;
                    (modal.querySelector('.save') as HTMLButtonElement).onclick = (e) => {
                        const input = (modal.querySelector('#url') as HTMLInputElement);
                        const parser = new YouTubeURLParser(input.value)
                        if(parser.isValid()) {
                            itemsLibraryInst.updateYoutubeItem(parser.getId(), item._id);
                            input.classList.remove('invalid');
                            this.closeModal(modal);
                        } else {
                            input.classList.add('invalid');
                        }
                    }
                    this.bindModalClose(modal);
                    break;
                case 'image':
                    modal = getById('image-modal-menu');
                    this.openModal(modal);
                    (modal.querySelector('#image-textarea') as HTMLTextAreaElement).value = item.content.description;
                    const dropAreaJpg = modal.querySelector('.drop-area');
                    (new FileUpload(dropAreaJpg)).hasImagePreview = true;
                    (modal.querySelector('.gallery img') as HTMLImageElement).removeAttribute('src');
                    const imageInput: HTMLInputElement = modal.querySelector('#jpg-file-input');
                    imageInput.value = '';
                    hide(modal.querySelector('.file-name'));

                    (modal.querySelector('.save') as HTMLButtonElement).onclick = async (e) => {

                        const descr = (modal.querySelector('#image-textarea') as HTMLTextAreaElement).value;
                        if (imageInput.files.length) {
                            const imageFile = imageInput.files[0];
                            const imageExt = imageInput.name.substring(imageInput.name.lastIndexOf('.')+1);
                            const storageRef = firebase.storage().ref();
                            const imagePath = this.userId + '/' + uuidv4() + '.' + imageExt;
                            let ref = storageRef.child(imagePath);
                            // btnProgressOn(modal.querySelector('.save'));
                            let snapshot = await ref.put(imageFile);
                            let jpgUrl;
                            try {
                                jpgUrl = await ref.getDownloadURL();
                                itemsLibraryInst.updateImageItem(jpgUrl, descr, item._id);
                                this.closeModal(modal);
                                // btnProgressOff(modal.querySelector('.save'));
                            } catch (e) {
                                console.error(e);
                                showMessage('#image-modal-menu .errors-output', e.toString());
                                // btnProgressOff(modal.querySelector('.save'));
                                return;
                            }
                        } else {
                            itemsLibraryInst.updateImageItem(item.content.url, descr, item._id);
                            this.closeModal(modal);
                        }

                    }
                    this.bindModalClose(modal);
                    break;
                case 'audio':
                    modal = getById('audio-modal-menu');
                    this.openModal(modal);
                    (modal.querySelector('#audio-textarea') as HTMLTextAreaElement).value = item.content.description;

                    const dropAreaAudio = modal.querySelector('.drop-area');
                    new FileUpload(dropAreaAudio);
                    (modal.querySelector('.save') as HTMLButtonElement).onclick = async (e) => {
                        const audioInput: HTMLInputElement = modal.querySelector('#audio-file-input');
                        const descr = (modal.querySelector('#audio-textarea') as HTMLTextAreaElement).value;
                        if (audioInput.files.length) {
                            const audioFile = audioInput.files[0];
                            const audioExt = audioFile.name.substring(audioFile.name.lastIndexOf('.')+1);
                            const storageRef = firebase.storage().ref();
                            const audioPath = this.userId + '/' + uuidv4() + '.' + audioExt;
                            let ref = storageRef.child(audioPath);
                            // btnProgressOn(modal.querySelector('.save'));
                            let snapshot = await ref.put(audioFile);
                            let audioUrl;
                            try {
                                audioUrl = await ref.getDownloadURL();
                                itemsLibraryInst.updateAudioItem(audioUrl, descr, item._id);
                                // btnProgressOff(modal.querySelector('.save'));
                                this.closeModal(modal);
                            } catch (e) {
                                console.error(e);
                                showMessage('#audio-modal-menu .errors-output', e.toString());
                                // btnProgressOff(modal.querySelector('.save'));
                                return;
                            }
                        } else {
                            itemsLibraryInst.updateAudioItem(item.content.url, descr, item._id);
                            this.closeModal(modal);
                        }

                    }
                    this.bindModalClose(modal);
                    break;
                case 'model':  break;
                case 'custom':  break;
                default: console.warn('Trying to edit wrong item type!')
            }
        }

        async function getModelsFromCsv(category: string) {
            const req = await fetch('Assets/ModelItemsList.csv');
            const lines = await req.text()
            const linksArray = CSV.parse(lines, null);
            linksArray.shift();
            return linksArray.filter(row => row[5] == category).map(row => {
                return {shortCode: row[0], imageUrl: row[3], title: row[4], category: row[5]}
            });
        }

        async function showCategoryModal(modal: HTMLElement, category: string) {
            if (!getById('model-category-tpl')) {
                self.addEditItemsTemplate('model-category-tpl');
                self.closeModal(modal);
                modal = getById('model-category');
                self.openModal(modal);
                self.bindModalClose(modal);
                (document.querySelector('#model-category .back-arrow') as HTMLElement).onclick = () => {
                    self.closeModals();
                    self.openModal(getById('model-categories'));
                }

                const ofCategory = await getModelsFromCsv(category);
                const list = getById('model-category').querySelector('.items-box');
                list.innerHTML = '';

                ofCategory.forEach(model => {
                    const imageUrl = model?.imageUrl || './Assets/img/placeholderVector.svg';
                    const itemTpl = `<div class="image"> <img src="${imageUrl}" alt="${model.title}"></div>`;
                    const div = document.createElement('div');
                    div.classList.add('items-box__item');
                    div.innerHTML = itemTpl;
                    div.title = model.title;
                    list.appendChild(div);
                    div.onclick = () => {
                        const newItem = new ModelItem();
                        newItem.content.shortCode = model.shortCode;
                        itemsLibraryInst.createCommonModel(newItem, true);
                        self.closeModal(modal);
                    }
                })
            }
        }

    }

    createMyItemCat(cat: string, list: Element, parentModal) {
        const item = document.createElement('div');
        item.classList.add('items-list__item', 'model-category');
        item.dataset.category = cat;

        const imageDiv = document.createElement('div');
        imageDiv.className = 'image';
        const icon: HTMLElement = document.createElement('i') as HTMLElement;
        icon.classList.add('fas', 'fa-2x', 'fa-'+itemCategories[cat].icon);
        imageDiv.appendChild(icon);
        item.appendChild(imageDiv);
        const title = document.createElement('div');
        title.className = 'title';
        title.innerHTML = itemCategories[cat].title;
        item.appendChild(title);
        list.appendChild(item);

        item.onclick = (e) => { this.showCustomCategoryModal(parentModal, cat) }
    }

    showCustomCategoryModal(parentModal: HTMLElement, category: string) {
        if (!getById('model-category-tpl')) {
            this.addEditItemsTemplate('model-category-tpl');
            this.closeModal(parentModal);
            const modal = getById('model-category');
            this.openModal(modal);
            this.bindModalClose(modal);
            (document.querySelector('#model-category .back-arrow') as HTMLElement).onclick = () => {
                this.closeModals();
                this.openModal(parentModal);
            }

            const list = modal.querySelector('.items-box');
            list.innerHTML = '';

            this.myItems.filter(item => item.category === category).forEach(model => {
                const imageUrl = model?.preview.jpg || './Assets/img/placeholderVector.svg';
                const itemTpl = `<div class="image"> <img src="${imageUrl}" alt="${model.title}"></div>`;
                const div = document.createElement('div');
                div.classList.add('items-box__item');
                div.innerHTML = itemTpl;
                div.title = model.title;
                list.appendChild(div);
                div.onclick = () => {
                    const newItem = new UserItem();
                    newItem.scale = model.scale;
                    newItem.position = model.position;
                    newItem.rotation = model.rotation;
                    const allowedKeys = ['userId', 'preview', 'file', 'title', 'description', 'category', 'license'];
                    newItem.content = Object.assign({}, ...allowedKeys.map(key=> ({[key]:model[key]})));
                    newItem.content.itemId = model._id;
                    itemsLibraryInst.createUserModel(newItem, true);
                    this.closeModal(modal);
                }
            })
        }
    }
    bindModalClose(modal: HTMLElement) {
        modal.querySelectorAll('.close-icon, .cancel').forEach((el: HTMLElement) => { el.onclick = () => { this.closeModal(modal) } })
    }

    private handleMyItemsModals() {
        getById('my-items-categories-tpl')?.remove()
        this.addEditItemsTemplate('my-items-categories-tpl');
        const modal = getById('my-items-categories');
        getById('myItemsMenu').onclick = () => {
            this.openModal(modal);
            const list = modal.querySelector('.items-list');
            list.innerHTML = '';
            const availableCats = this.myItems.map(item => item.category);
            for (let cat in itemCategories) {
                availableCats.includes(cat) && this.createMyItemCat(cat, list, modal);
            }
        }
        this.bindModalClose(modal);
    }

    private initEditLayer() {
        const s = async () => {
            const html = await ( await fetch( 'src/edit-items.html' ) ).text();
            this.editItemTemplates = document.createElement('template')
            this.editItemTemplates.innerHTML = html;
            this.addEditItemsTemplate('user-menu-modal-menu-tpl');
            getById('profile-menu').onclick = () => {
                this.closeModals();
                toggle(getById('user-menu-modal-menu'));
                getById('root').classList.toggle('modal-opened');
            }
            (document.querySelector('#user-menu-modal-menu .close-icon') as HTMLElement).onclick = e => {
                const el = getById('user-menu-modal-menu');
                hide(el);
                getById('root').classList.remove('modal-opened')
            }
            getById('my-layers-btn').onclick = () => { this.closeModals(); this.openMyLayers(); };
            getById('upload-model-btn').onclick = () => { this.closeModals(); this.mapstarUpload.openUploadModel();  }
            getById('sign-out').onclick = () => { this.mapstarAuth.signOut(); this.closeModals(); }
            this.getMyLayers();
        };
        s();
        getById('edit-model-btn').onclick = () => {
            if (!this.isEditMode) {
                this.enterEditMode();
            }
        };
        getById('cancel-edit-btn').onclick = () => {
            if (this.ifItemsChanged()) {
                window.location.reload();
            } else {
                this.exitEditMode();
            }
        };
        getById('delete-model-btn').onclick = () => {
            this.addConfirmModal('Are you sure to delete this model?', 'Yes, delete model', 'No, cancel',
              () => {console.log('TODO delete')}, () => this.removeConfirmModal())
        }
        getById('save-model-btn').onclick = () => {
            saveItems();
        };
        getById('removeMenu').onclick = () => {
            itemsLibraryInst.removeItem();
            hide(getById('removeMenu'));
            getById('root').classList.remove('modal-opened')
        }

        (document.querySelector('#my-layers .close-icon') as HTMLElement).onclick = () => { this.closeMyLayers() };
    }
    openMyLayers() {
        this.closeModals();
        setTimeout(this.initSlider, 0);
        show(getById('my-layers'));
    }
    closeMyLayers() {
        hide(getById('my-layers'));
    }
    private enterEditMode() {
        this.watched.isEditMode = true;
        itemsLibraryInst.enterEditMode();

        hide(getById('edit-model-btn'));
        hide(getById('delete-model-btn'));
        hide(getById('stats'));
        hide(getById('user-menu'));
        hide(getById('stats'));
        show(getById('cancel-edit-btn'));
        show(getById('save-model-btn'));
        show(getById('edit-mode-indicator'));
        show(getById('add-item-menu'));
        this.isOwner() && show(document.querySelector('#location .edit-btn'))

        this.bindEditModeModals();
    }

    exitEditMode() {
        this.watched.isEditMode = false;
        itemsLibraryInst.exitEditMode();
        hide(getById('save-model-btn'));
        hide(getById('cancel-edit-btn'));
        this.showEditBtn();
        show(getById('stats'));
        hide(getById('edit-mode-indicator'));
        hide(getById('add-item-menu'));
        show(getById('stats'));
        show(getById('delete-model-btn'));
        getById('root').classList.remove('modal-opened');
        this.isEditLayerInfo = false;
        getById('location').classList.remove('active');
        hide(document.querySelector('#location .edit-btn'))
    }

    getMyLayers() {
        const singeLayerTpl: HTMLTemplateElement = getById('ui-layer').querySelector('template#single-layer-tpl');
        this.watched.isMyModelsReady = false;
        this.isAuthed && functions.httpsCallable('ReadMyFeed')({"limitResults": this.config?.limitReadMyFeed || 20}).then(resp => {
            const myLayers = getById('my-layers-list');
            myLayers.innerHTML = '';
            if (typeof resp?.data?.result?.layers != "undefined") {
                resp.data.result.layers.forEach((l: FeedReconstruction['layer'], i) => {
                    const layerEl = document.createElement('div');
                    layerEl.classList.add('layer', 'keen-slider__slide');
                    layerEl.appendChild(singeLayerTpl.content.cloneNode(true));
                    (l.preview?.jpg) &&
                    ((layerEl.querySelector('.layer-preview img') as HTMLImageElement).src = l.preview.jpg);
                    layerEl.querySelector('.layer-link').innerHTML = l.title;
                    layerEl.querySelector('.layer-descr').innerHTML = l.description;
                    layerEl.querySelector('.layer-date').innerHTML = new Date(l.lastUpdated)
                        .toLocaleDateString('de-DE', {hour: '2-digit', hour12: false, minute: '2-digit'});
                    (layerEl.querySelector('.layer-link') as HTMLLinkElement).href = '?id=' + l.reconstructionId;

                    (layerEl.querySelectorAll('.layer-preview, .layer-link').forEach((el: HTMLLinkElement) => el.onclick = e => {
                        if(this.isEditMode) {
                            this.triggerEditConfirm(() => {window.open('?id=' + l.reconstructionId, "_blank")});
                            e.preventDefault();
                        } else {
                            reloadReconstruction(l.reconstructionId);
                            this.closeMyLayers();
                        }
                    }));

                    layerEl.querySelectorAll('.open-context-panel, .close-icon').forEach((el: HTMLElement) => el.onclick = e => {
                        toggle(layerEl.querySelector('.context-panel'))
                    });
                    (layerEl.querySelector('.delete-model') as HTMLElement).onclick = e => {
                        // deleteLayer(l.reconstructionId)
                        this.addConfirmModal('Are you sure to delete you model?', 'Yes, delete', 'No, cancel',
                          () => {console.log('TODO delete')}, () => this.removeConfirmModal())
                    }

                    const locationCoords = resp.data.result.anchors[i].data.coordinates;
                    this.makeReverseGeocoding(locationCoords, (text) => {
                        layerEl.querySelector('.layer-location').innerHTML = text;
                    });
                    myLayers.appendChild(layerEl);
                })
                this.initSlider();
                this.watched.isMyModelsReady = true;
            } else {
                myLayers.innerHTML = "Create your first models with Mapstar's mobile Android or iOS application or upload your existing models via the main menu.";
                this.initSlider();
                this.watched.isMyModelsReady = true;
            }
        }).catch( (error) => {
            console.error("Error calling ReadMyFeed:", error);
            this.watched.isMyModelsReady = true;
        })
    }

    private initSlider() {
        const breakpoints: any = {};
        const slideWidth = 230;
        breakpoints[`(min-width: 0px) and (max-width: 575px)`] = { slidesPerView: 1 }
        let n = 2;
        for (let i = 576; i < 4000; i += slideWidth + 20) {
            breakpoints[`(min-width: ${i}px) and (max-width: ${i + slideWidth + 20}px)`] = { slidesPerView: n++ }
        }
        var slider = new KeenSlider(".keen-slider", {
            created: function (instance) {
                getById('arrow-left').onclick = () => instance.prev();
                getById('arrow-right').onclick = () => instance.next();
                updateClasses(instance)
            },
            slideChanged(instance) {
                updateClasses(instance)
            },
            mounted: instance => {
                if (window.innerWidth <= 575)
                {
                    getById('my-layers').style.width = '100%';
                    var imgSize = window.innerWidth - 80;
                    var nodeList = document.querySelectorAll(".layer.keen-slider__slide img");
                    for (let i = 0; i < nodeList.length; i++) {
                        nodeList[i].setAttribute("style", "width:"+imgSize+"px;height:"+imgSize+"px;");
                    }
                    // getById('my-layers').style.width = window.innerWidth+'px';
                }
                else
                {
                    getById('my-layers').style.width = slideWidth * instance.details().slidesPerView + 60 +'px';
                }
                instance.resize();
            },
            slidesPerView: 10,
            breakpoints: breakpoints,
        })

        function updateClasses(instance) {
            var slide = instance.details().relativeSlide
            var arrowLeft = document.getElementById("arrow-left")
            var arrowRight = document.getElementById("arrow-right")
            slide === 0
                ? arrowLeft.classList.add("arrow--disabled")
                : arrowLeft.classList.remove("arrow--disabled")
            slide === instance.details().size - 1
                ? arrowRight.classList.add("arrow--disabled")
                : arrowRight.classList.remove("arrow--disabled")
        }
    }

    private makeReverseGeocoding(coords: any, cb: CallableFunction) {
        const geocodingUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + coords.long + ',' + coords.lat + '.json?access_token=' + config.mapboxToken
        fetch(geocodingUrl).then(resp => {
            resp.json().then(data => {
                let text = '';
                if(data.features.length) {
                    const place = data.features.filter(f => f.place_type.includes("place"));
                    text =  data.features[0].place_name;
                    if (place.length) {
                        let parts = place.shift().place_name.split(',').map(p => p.trim());
                        (parts.length >= 3) && parts.splice(1, 1)
                        text = parts.join(', ');
                    }
                } else {
                    text = parseFloat(coords.long).toFixed(6) + '; ' + parseFloat(coords.lat).toFixed(6)
                }

                cb(text);
            })
        }).catch(err => {
            console.error('Mapbox reverse geocoding failed', err);
        });
    }

    showCreatorPanel() {
        if (this.isAuthed) {
            this.isOwner() ? hide(getById('creator')) : showData();
        } else {
            showData();
        }

        function showData() {
            show(getById('creator'));
            if (data.userArray[0].profilePicture) {
                (document.querySelector('#creator .avatar img') as HTMLImageElement).src = data.userArray[0].profilePicture || '';
            }
            document.querySelector('#creator .name').innerHTML = data.userArray[0].displayName;
            document.querySelector('#creator .info-text').innerHTML = data.userArray[0].infoText;
        }
    }
    openModal(el) {
        this.closeModals();
        show(el);
        getById('root').classList.add('modal-opened')
    }
    closeModal(el) {
        hide(el);
        getById('root').classList.remove('modal-opened')
    }

    private addEditItemsTemplate(templateId) {
        if(!this.editItemTemplates) { console.warn('Cannot add template '+ templateId); return }
        const template = this.editItemTemplates.content.querySelector('#'+templateId)
        const div = document.createElement('div');
        div.innerHTML = template.innerHTML;
        getById('edit-items-modals').appendChild(div);
    }
    addUiTemplate(templateId, container = '#ui-layer', contentOnly = false) {
        if(!this.uiTemplates) { return }
        const template: HTMLTemplateElement = this.uiTemplates.content.querySelector('#'+templateId)

        if(contentOnly) {
            // add template content nodes to modal body
            const children = template.content.firstElementChild.children;
            for (let i = 0; i < children.length; i++) {
                document.querySelector(container).appendChild(children[i].cloneNode(true));
            }
        } else {
            // add content with id wrapper
            document.querySelector(container).appendChild(template.content.cloneNode(true));
        }

    }

    isOwner() {
        return data.reconstruction.userId === this.userId;
    }
    closeModals() {
        document.querySelectorAll('.menu-modal').forEach(m => hide(m));
        getById('root').classList.remove('modal-opened');
        hide(getById('my-layers'));
        hide(getById('upload-model'));
    }
    triggerEditConfirm(callback: CallableFunction) {
        show(getById('edit-confirm'));
        getById('continue').onclick = () => { hide(getById('edit-confirm')) }
        getById('leave').onclick = () => {
            hide(getById('edit-confirm'));
            this.exitEditMode();
            callback();
        }
        getById('save-and-leave').onclick = () => {
            hide(getById('edit-confirm'));
            this.exitEditMode();
            saveItems();
            callback();
        }
    }

    private ifItemsChanged() {
        const compareKeys = ['position', 'rotation', 'scale', 'content'];
        let currentItems = itemsLibraryInst.prepareForSaving();

        const refinedInitial = Object.values(data.layer[0].items).map(o => ItemsLibrary.normalizeItem(o))
          .map(o => filterObjKeys(o, compareKeys)).map(o => sortObject(o));
        const refinedCurrent = currentItems.map(o => ItemsLibrary.normalizeItem(o))
          .map(e => filterObjKeys(e, compareKeys)).map(o => sortObject(o));

        const sortedInitial = refinedInitial.sort((a, b) => md5(JSON.stringify(a)) > md5(JSON.stringify(b)) ? 1 : -1);
        const sortedCurrent = refinedCurrent.sort((a, b) => md5(JSON.stringify(a)) > md5(JSON.stringify(b)) ? 1 : -1);

        return !deepEqual(sortedInitial, sortedCurrent);
    }

    addConfirmModal(descr, yesText, noText, yesCb, noCb) {
        this.addUiTemplate('confirm-modal-tpl');
        const modal = getById('confirm-modal');
        modal.querySelector('.description').innerHTML = descr;
        modal.querySelector('#confirm').innerHTML = yesText;
        modal.querySelector('#decline').innerHTML = noText;
        (modal.querySelector('#confirm') as HTMLElement).onclick = yesCb;
        (modal.querySelector('#decline') as HTMLElement).onclick = noCb;
    }
    removeConfirmModal() {
        getById('confirm-modal')?.remove();
    }

    addSuccessAlert(info) {
        this.addAlert(info, 'success');
    }

    addErrorAlert(info) {
        this.addAlert(info, 'error');
    }

    private addAlert(info, type) {
        document.querySelector('.alert')?.remove();
        this.addUiTemplate('alert-'+type+'-tpl');
        const alert = getById('alert-'+type);
        alert.querySelector('.info').innerHTML = info;
        (alert.querySelector('.close-icon') as HTMLElement).onclick = () => alert?.remove();
        setTimeout(() => document.querySelector('.alert')?.remove(), 5000);
    }
}

// trigger content area resize after opening side panel; .modal-opened
const mutationObserver = new MutationObserver(mutationsList => {
        mutationsList.forEach(mutation => { (mutation.attributeName === 'class') && engine.resize() })
    })
mutationObserver.observe(getById('root'), { attributes: true })
