export function getById(id) { return document.getElementById(id); }

export function toggle(el) { el?.classList?.toggle('hidden') }

export function hide(el) { el?.classList?.add('hidden') }

export function show(el) { el?.classList?.remove('hidden') }

export function showMessage(selector, text) {
    document.querySelector(selector).innerHTML = text;
    setTimeout(() => {document.querySelector(selector).innerHTML = ''}, 5000)
}

export function showInvalidField(selector) {
    document.querySelector(selector).classList.add('invalid');
    setTimeout(() => {document.querySelector(selector).classList.remove('invalid')}, 3000)
}

export function btnProgressOn(btn) {
    btn.classList.add('active');
    btn.disabled = true;
}

export function btnProgressOff(btn) {
    btn.classList.remove('active');
    btn.disabled = false;
}

export function filterObjKeys(obj, keysToKeep) {
    return keysToKeep.reduce((o, key) => ({ ...o, [key]: obj[key] }), {});
}

export function sortObject(object) {
    //Thanks > http://whitfin.io/sorting-object-recursively-node-jsjavascript/
    if (!object) {
        return object;
    }

    const isArray = object instanceof Array;
    var sortedObj = {};
    if (isArray) {
        sortedObj = object.map((item) => sortObject(item));
    } else {
        var keys = Object.keys(object);
        // console.log(keys);
        keys.sort(function(key1, key2) {
            (key1 = key1.toLowerCase()), (key2 = key2.toLowerCase());
            if (key1 < key2) return -1;
            if (key1 > key2) return 1;
            return 0;
        });

        for (var index in keys) {
            var key = keys[index];
            if (typeof object[key] == 'object') {
                sortedObj[key] = sortObject(object[key]);
            } else {
                sortedObj[key] = object[key];
            }
        }
    }

    return sortedObj;
}

export function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
}
