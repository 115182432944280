export class Env {
    skyboxes = [
        "Assets/skybox/MapstarSkybox.env",
        "https://assets.babylonjs.com/environments/environmentSpecular.env",
        "https://assets.babylonjs.com/environments/studio.env",
    ];

    readLocaStorageValue = function(key, defautlValue) {
        if (typeof (Storage) !== "undefined" && localStorage.getItem(key) !== null) {
            return parseInt(localStorage.getItem(key));
        }

        return defautlValue;
    }

    defaultSkyboxIndex = this.readLocaStorageValue("defaultSkyboxId", 0);
    loadSkyboxPathTexture(path, scene) {
        if (path.indexOf(".hdr") === (path.length - 4)) {
            return new BABYLON.HDRCubeTexture(path, scene, 256, false, true, false, true);
        }
        return BABYLON.CubeTexture.CreateFromPrefilteredData(path, scene);
    }
}
